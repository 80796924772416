import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';

import DeviareLeaderboardHeader from './DeviareLeaderboardHeader';

const DeviareLeaderboard = ({ batchData }) => {
  const fetchLeaderboard = async () => {
    const res = await axios.get(
      API_URL + '/dashboard/partner/deviare-leaderboard',
      {
        params: { batchId: batchData._id },
      }
    );
    return res;
  };

  const { status, data } = useQuery(
    `DeviareLeaderboard${batchData._id}`,
    fetchLeaderboard
  );

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <DeviareLeaderboardHeader data={data.data.data} batchData={batchData} />
      )}
    </div>
  );
};

export default DeviareLeaderboard;
