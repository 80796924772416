import React, { useState } from 'react';

import CapstoneSummaryTable from './CapstoneSummaryTable';
import CapstoneDetails from '../capstoneDetails';
import NewProfile from '../../layouts/NewProfile';

const Index = () => {
  const [currentCapstone, setCurrentCapstone] = useState(null);
  const [showProfile, setShowProfile] = useState(null);

  return (
    <div>
      {showProfile ? (
        <NewProfile setShowProfile={setShowProfile} userId={showProfile} />
      ) : (
        <>
          {currentCapstone ? (
            <CapstoneDetails
              currentCapstone={currentCapstone}
              setCurrentCapstone={setCurrentCapstone}
              setShowProfile={setShowProfile}
            />
          ) : (
            <CapstoneSummaryTable setCurrentCapstone={setCurrentCapstone} />
          )}
        </>
      )}
    </div>
  );
};

export default Index;
