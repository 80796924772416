import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, CircularProgress, Typography } from '@mui/material';

import AllProjects from './AllProjects';
import StudentProjects from './StudentProjects';
import TimeSheet from '../submissions/TimeSheet';

import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Index({ batchData }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchProjects = async () => {
    const res = await axios.get(API_URL + '/batch/user-vga-project', {
      params: { batchId: batchData._id },
    });
    return res;
  };

  const { status, data } = useQuery(`Projects${batchData._id}`, fetchProjects);

  return (
    <>
      {' '}
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label=''>
              <Tab label='Task List' {...a11yProps(0)} />
              <Tab label='Student List' {...a11yProps(1)} />

              {batchData.timeSheet && batchData.timeSheet.startsAt && (
                <Tab label='Timesheet' {...a11yProps(2)} />
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <AllProjects allTasks={data.data.allTasks} data={data.data.data} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <StudentProjects
              allTasks={data.data.allTasks}
              allSubmission={data.data.data}
            />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <TimeSheet batchData={batchData} />
          </TabPanel>
        </Box>
      )}
    </>
  );
}
