import React, { useState, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import axios from 'axios';
import { API_URL } from '../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import {
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import CapstoneTableBody from './CapstoneTableBody';

import TableListHead from '../../layouts/batch/TableListHead';

import { useQuery } from 'react-query';
import { applySortFilter, getComparator } from '../../helper/TableShort';

const fetchCapstone = async (params) => {
  const { limit, page, sort, order, searchRequestKeyword } = params;
  const res = await axios.get(API_URL + '/dashboard/partner/capstone', {
    params: { limit, page, sort, order, searchKeyword: searchRequestKeyword },
  });

  return res;
};
const debounce = (fn, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};

function CapstoneSummaryTable({ setCurrentCapstone }) {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchRequestKeyword, setSearchRequestKeyword] = useState('');

  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 10,
    sort: 'firstName',
    order: 'asc',
  });

  const { status, data, isFetching } = useQuery(
    [`fetchCapstone`, paginationData, searchRequestKeyword],
    () => fetchCapstone({ ...paginationData, searchRequestKeyword }),
    { keepPreviousData: true }
  );
  const debouncedHandler = useCallback((value, duration = 1200) => {
    debounce(() => setSearchRequestKeyword(value), duration)();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc =
      paginationData.sort === property && paginationData.order === 'asc';
    setPaginationData({
      ...paginationData,
      sort: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const TABLE_HEAD = [
    { id: 'firstName', label: 'Name', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    { id: 'enrolled', label: 'Enrolled Capstones', align: 'center' },
    { id: 'completed', label: 'Completed Capstones', align: 'left' },
    { id: 'remaining', label: 'Remaining Capstones', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPaginationData({
      ...paginationData,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      page: 0,
      limit: event.target.value,
    });
  };

  return (
    <>
      <TextField
        fullWidth
        label='Search be First Name or Email'
        value={searchKeyword}
        onChange={(e) => {
          setSearchKeyword(e.target.value);
          debouncedHandler(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                onClick={() => {
                  setSearchKeyword('');
                  debouncedHandler('', 0);
                }}
                edge='end'
              >
                {searchKeyword ? <CloseIcon /> : <SearchIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Typography mt={1} variant='caption' color='error'>
        *Sort and search by name and email only
      </Typography>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}

      {status === 'success' && (
        <TableContainer>
          <Table
            sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
            aria-label='table'
            size='small'
          >
            <TableListHead
              order={paginationData.order}
              orderBy={paginationData.sort}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {applySortFilter(
                data.data.data,
                getComparator(paginationData.order, paginationData.sort)
              ).map((row) => (
                <CapstoneTableBody
                  row={row}
                  key={row._id}
                  setCurrentCapstone={setCurrentCapstone}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[...new Set([paginationData.limit, 5, 10, 50])]}
            component='div'
            count={data.data.totalCount}
            rowsPerPage={paginationData.limit}
            page={paginationData.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            nextIconButtonProps={{
              disabled:
                isFetching || data.data.page === data.data.totalPages - 1,
            }}
          />
        </TableContainer>
      )}
    </>
  );
}

export default CapstoneSummaryTable;
