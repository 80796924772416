import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import TableListHead from '../../../layouts/batch/TableListHead';
import { applySortFilter, getComparator } from '../../../helper/TableShort';

export default function AllSubmissions({ allTasks, data }) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('dueDateTime');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const TABLE_HEAD = [
    {
      id: 'title',
      label: 'Task Name',
      align: 'left',
    },
    {
      id: 'dueDateTime',
      label: 'Due On',
      align: 'left',
    },
    {
      id: 'submitted',
      label: 'Submitted',
      align: 'left',
    },
    {
      id: 'pending',
      label: 'Pending',
      align: 'left',
    },
    {
      id: 'submissionRate',
      label: 'Submission Rate',
      align: 'left',
    },
  ];
  const activeUser = data.filter((user) => user.status === 'Active');
  const submissionsData = allTasks.map((task) => {
    const submitted = activeUser.filter((user) => {
      const userTask = user.tasks.filter((userOneTask) => {
        return (
          userOneTask._id === task._id &&
          userOneTask.submission &&
          userOneTask.submission.submittedFile
        );
      }).length;
      return userTask;
    }).length;

    const eligibleUser = activeUser.filter((user) => {
      const userEvent = user.tasks.filter((userOneTask) => {
        return userOneTask._id === task._id;
      }).length;
      return userEvent;
    }).length;
    const pending = eligibleUser - submitted;
    const submissionRate = eligibleUser
      ? Math.round((submitted * 100) / eligibleUser)
      : 0;
    return {
      ...task,
      submitted: submitted,
      total: eligibleUser,
      pending: pending,
      submissionRate: submissionRate,
    };
  });

  const filteredUsers = applySortFilter(
    submissionsData,
    getComparator(order, orderBy)
  );
  return (
    <TableContainer sx={{ maxHeight: 450 }}>
      <Table
        sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
        stickyHeader
        aria-label='sticky table'
        size='small'
      >
        <TableListHead
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEAD}
          rowCount={submissionsData.length}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {filteredUsers.map((task, index) => (
            <TableRow key={index}>
              <TableCell align='left'>{task.title}</TableCell>
              <TableCell align='center'>
                {task.dueDateTime &&
                  format(new Date(task.dueDateTime), 'do LLL yy')}{' '}
              </TableCell>
              <TableCell align='center'>{task.submitted}</TableCell>
              <TableCell align='center'>{task.pending}</TableCell>
              <TableCell align='center'>
                {Math.round((task.submitted * 100) / task.total)} %
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
