import React, { useState } from 'react';
// import Table from '@mui/material/Table';
// import Box from '@mui/material/Box';
// import TableBody from '@mui/material/TableBody';
// import Collapse from '@mui/material/Collapse';
// import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import { TableRow } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

function NewLeaderboardBody({ row }) {
  const [open, setOpen] = useState(false);

  const useStyles = makeStyles((theme) => ({
    nameColor: {
      color: open ? 'blue' : '',
    },
  }));

  const classes = useStyles();

  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component='th' scope='row' padding='none'>
          <Typography
            variant='subtitle2'
            className={classes.nameColor}
            onClick={() => setOpen(!open)}
            sx={{ cursor: 'pointer' }}
            noWrap
          >
            {row.name}
          </Typography>
        </TableCell>
        <TableCell align='center'> {row.belongScore.toFixed(2)} % </TableCell>
        <TableCell align='center'>
          {row.profileStrength.toFixed(2)} /15{' '}
        </TableCell>
        <TableCell align='center'> {row.aptitudeScore} % </TableCell>
        <TableCell align='center'> {row.vgaScore} % </TableCell>
        <TableCell align='center'>
          {' '}
          {row.attendanceRate.toFixed(2)} %{' '}
        </TableCell>
        <TableCell align='center'> {row.submissionRate} % </TableCell>
        <TableCell align='center'> {row.quizScore} % </TableCell>
        <TableCell align='center'> {row.projectTaskScore} % </TableCell>
        <TableCell align='center'> {row.pitchPresentation} % </TableCell>
        <TableCell align='center'>
          {row.completionStatus === 'Not Applicable'
            ? '-'
            : row.completionStatus}
        </TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Coach</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Mentor</TableCell>
                    <TableCell>Area</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row.coach}</TableCell>
                    <TableCell>{row.company}</TableCell>
                    <TableCell>{row.mentor}</TableCell>
                    <TableCell>{row.area}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </>
  );
}

export default NewLeaderboardBody;
