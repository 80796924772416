import React from 'react';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { format } from 'date-fns';
import { Box, Typography, Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    "url('https://res.cloudinary.com/belong/image/upload/v1643358849/uploaded_resources/borders_svhcan.png')",
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  padding: theme.spacing(3),
  margin: theme.spacing(1.5, 0),
}));

const DeviareCertificate = ({ batchData, batchDetails }) => {
  const userDetails = batchData.users[0];

  const completionStatus = batchData?.completionStatus;
  const mentorSign = userDetails?.mentor.mentorSign;

  const certificateText = 'CERTIFICATE OF ' + completionStatus.toUpperCase();
  const areaText = userDetails?.area.title;
  const companyName = userDetails?.company.name;
  const topRightLogo = batchDetails.logoUrl;
  const belongLogo =
    'https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png';
  // const topRightLogo =
  //   batchData.type === 'University' ? belongLogo : batchData.logoUrl;
  const leftLogo = belongLogo;

  const durationText = `duration ${format(
    new Date(batchDetails.startDate),
    'do LLLL y'
  )} to ${format(new Date(batchDetails.endDate), 'do LLLL y')}.`;

  return (
    <Container>
      <Box
        sx={{
          minWidth: '900px',
          maxWidth: '900px',
          mx: 'auto',
          px: 2,
          border: '2px solid',
          borderTop: 0,
          borderBottom: 0,
          bgcolor: 'grey.100',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledBox>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mb: 3,
                pr: 6,
                pt: { xs: 1, md: 2 },
              }}
            >
              <img src={topRightLogo} alt='Logo' style={{ height: '3rem' }} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: { xs: 1, sm: 0.5, md: 0.25 },
                mt: { xs: -8, md: -10 },
              }}
            >
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1643358778/uploaded_resources/badge_fec7cf.png'
                alt='Logo'
                style={{ height: '6rem' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                px: 6,
              }}
            >
              <Typography
                variant='h4'
                sx={{
                  mb: 1,
                  ml: '25%',
                  letterSpacing: 8,
                  width: '100%',
                  textAlign: 'center', // Added to center the text horizontally
                }}
              >
                {certificateText}
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  mb: 2,
                  ml: '15%',
                  fontWeight: 'bold',
                  letterSpacing: 4,
                  textAlign: 'center', // Added this line to center the text horizontally
                  width: '100%', // Added to ensure full width
                }}
              >
                THIS CERTIFICATE IS PRESENTED TO
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    borderBottom: '2px solid',
                  }}
                >
                  <BiLeftArrow color='primary' />
                  <Typography
                    variant='h4'
                    sx={{
                      fontWeight: 'bold',
                      fontFamily: 'Abhaya Libre',
                      letterSpacing: 4,
                    }}
                  >
                    {userDetails?.name?.toUpperCase()}
                  </Typography>
                  <BiRightArrow color='primary' />
                </Box>
              </Box>

              {completionStatus === 'Completion' ? (
                <Box>
                  <Typography sx={{ my: 3 }}>
                    for successful completion of{' '}
                    <Box component='span' sx={{ fontWeight: 'bold' }}>
                      Virtual Global Apprenticeship
                    </Box>{' '}
                    in {areaText}. This achievement stands as evidence of
                    his/her valuable input to the{' '}
                    <Box component='span' sx={{ fontWeight: 'bold' }}>
                      {' '}
                      {companyName}{' '}
                    </Box>{' '}
                    project, for the{' '}
                    <Box component='span' sx={{ fontWeight: 'bold' }}>
                      {durationText}
                    </Box>
                  </Typography>
                  <Typography
                    variant='h4'
                    color='primary'
                    sx={{
                      fontWeight: 'bold',
                      fontFamily: 'Abhaya Libre',
                      textAlign: 'center', // Added this line to center the text horizontally
                      width: '100%', // Added to ensure full width
                    }}
                  >
                    Congratulations on this Achievement !
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography sx={{ my: 3 }}>
                    in recognition of his/her outstanding performance and
                    commitment during{' '}
                    <Box component='span' sx={{ fontWeight: 'bold' }}>
                      Virtual Global Apprenticeship
                    </Box>{' '}
                    in {areaText} . This achievement stands as evidence of
                    his/her valuable input to the{' '}
                    <Box component='span' sx={{ fontWeight: 'bold' }}>
                      {' '}
                      {companyName}{' '}
                    </Box>{' '}
                    project, for the duration{' '}
                    <Box component='span' sx={{ fontWeight: 'bold' }}>
                      {durationText}
                    </Box>
                  </Typography>
                  <Typography
                    variant='h4'
                    color='primary'
                    sx={{
                      fontWeight: 'bold',
                      fontFamily: 'Abhaya Libre',
                    }}
                  >
                    Congratulations on this Achievement !
                  </Typography>
                </>
              )}
            </Box>
            <Grid
              container
              justifyContent='space-between'
              sx={{ px: 8, mt: 6 }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img
                  src='https://res.cloudinary.com/belong/image/upload/v1643353725/uploaded_resources/Deviare_sign_g7kcav.jpg'
                  alt='Logo'
                  style={{ height: '2.5rem' }}
                />
                <Typography
                  sx={{
                    borderTop: '2px solid grey',
                    fontWeight: 'bold',
                    letterSpacing: 2,
                    color: 'grey.600',
                    pt: 1,
                    textAlign: 'center',
                    fontSize: '0.875rem',
                  }}
                >
                  DEVIARE DIRECTOR
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img src={mentorSign} alt='Logo' style={{ height: '2.5rem' }} />
                <Typography
                  sx={{
                    borderTop: '2px solid grey',
                    fontWeight: 'bold',
                    letterSpacing: 2,
                    color: 'grey.600',
                    pt: 1,
                    textAlign: 'center',
                    fontSize: '0.875rem',
                  }}
                >
                  INDUSTRY MENTOR
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src='https://res.cloudinary.com/belong/image/upload/v1643353663/uploaded_resources/One_no4yyb.gif'
                    alt='Logo'
                    style={{ height: '2.5rem' }}
                  />
                  <img
                    src='https://res.cloudinary.com/belong/image/upload/v1643353575/uploaded_resources/Two_yy5ee6.gif'
                    alt='Logo'
                    style={{ height: '2.5rem' }}
                  />
                </Box>
                <Typography
                  sx={{
                    borderTop: '2px solid grey',
                    fontWeight: 'bold',
                    letterSpacing: 2,
                    color: 'grey.600',
                    pt: 1,
                    textAlign: 'center',
                    fontSize: '0.875rem',
                  }}
                >
                  BELONG FOUNDERS
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', pl: 3, mb: 2 }}>
              <img src={leftLogo} alt='Logo' style={{ height: '2rem' }} />
            </Box>
          </StyledBox>
        </Box>
      </Box>
    </Container>
  );
};

export default DeviareCertificate;
