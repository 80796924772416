import React from 'react';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { format } from 'date-fns';
import { Box, Typography, Container, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundImage:
    "url('https://res.cloudinary.com/belong/image/upload/v1643358849/uploaded_resources/borders_svhcan.png')",
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  padding: theme.spacing(3),
  margin: theme.spacing(1.5, 0),
}));

const DeviareInternshipCertificate = ({ batchData, batchDetails }) => {
  const userDetails = batchData.users[0];

  const areaText = userDetails.area.title;
  const mentorSign = userDetails?.mentor.mentorSign;

  const companyName = userDetails?.company2?.name
    ? userDetails.company2.name
    : userDetails.company.name;
  const belongLogo =
    'https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png';

  const topRightLogo = batchDetails.logoUrl;
  const leftLogo = belongLogo;

  const durationText = ` ${format(
    new Date(batchDetails.startDate),
    'do LLLL y'
  )} to ${format(new Date(batchDetails.endDate), 'do LLLL y')}.`;

  return (
    <Container>
      <Box
        sx={{
          minWidth: '900px',
          maxWidth: '1000px',
          mx: 'auto',
          px: 2,
          border: '2px solid',
          borderTop: 0,
          borderBottom: 0,
          bgcolor: 'grey.100',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <StyledBox>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mb: 3,
                pr: 6,
                pt: { xs: 1, md: 2 },
              }}
            >
              <img src={topRightLogo} alt='Logo' style={{ height: '3rem' }} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: { xs: 3, sm: 1, md: 0.5 },
                mt: { xs: -8, md: -10 },
              }}
            >
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1643358778/uploaded_resources/badge_fec7cf.png'
                alt='Logo'
                style={{ height: '6rem' }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                px: 6,
              }}
            >
              <Typography
                variant='h4'
                sx={{
                  mb: 1,
                  ml: '25%',
                  letterSpacing: 8,
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                CERTIFICATE FOR FULL-TIME VGA
                <br />
                (6-Month Internship)
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  mb: 2,
                  ml: '15%',
                  fontWeight: 'bold',
                  letterSpacing: 4,
                  textAlign: 'center', // Added this line to center the text horizontally
                  width: '100%',
                }}
              >
                THIS CERTIFICATE IS PRESENTED TO
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    borderBottom: '2px solid',
                  }}
                >
                  <BiLeftArrow color='primary' />
                  <Typography
                    variant='h4'
                    sx={{
                      fontWeight: 'bold',
                      fontFamily: 'Abhaya Libre',
                      letterSpacing: 4,
                    }}
                  >
                    {userDetails.name.toUpperCase()}
                  </Typography>
                  <BiRightArrow color='primary' />
                </Box>
              </Box>

              <Typography sx={{ my: 3, textAlign: 'center' }}>
                in recognition of their outstanding performance and dedication
                towards the completion of the{' '}
                <Box component='span' sx={{ fontWeight: 'bold' }}>
                  Virtual Internship with {companyName}{' '}
                </Box>
                from{' '}
                <Box component='span' sx={{ fontWeight: 'bold' }}>
                  {durationText}
                </Box>
                We commend their commitment to personal growth and skill
                development. We believe they have the potential to achieve
                significant excellence in the field of{' '}
                <Box component='span' sx={{ fontWeight: 'bold' }}>
                  {areaText}
                </Box>
              </Typography>
              <Typography
                variant='h4'
                color='primary'
                sx={{
                  fontWeight: 'bold',
                  fontFamily: 'Abhaya Libre',
                  textAlign: 'center',
                }}
              >
                Congratulations on this Achievement !
              </Typography>
            </Box>
            <Grid
              container
              justifyContent='space-between'
              sx={{ px: 8, mt: 6 }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img
                  src='https://res.cloudinary.com/belong/image/upload/v1643353725/uploaded_resources/Deviare_sign_g7kcav.jpg'
                  alt='Logo'
                  style={{ height: '2.5rem' }}
                />
                <Typography
                  sx={{
                    borderTop: '2px solid grey',
                    fontWeight: 'bold',
                    letterSpacing: 2,
                    color: 'grey.600',
                    pt: 1,
                    textAlign: 'center',
                    fontSize: '0.875rem',
                  }}
                >
                  DEVIARE DIRECTOR
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img src={mentorSign} alt='Logo' style={{ height: '2.5rem' }} />
                <Typography
                  sx={{
                    borderTop: '2px solid grey',
                    fontWeight: 'bold',
                    letterSpacing: 2,
                    color: 'grey.600',
                    pt: 1,
                    textAlign: 'center',
                    fontSize: '0.875rem',
                  }}
                >
                  INDUSTRY MENTOR
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <img
                    src='https://res.cloudinary.com/belong/image/upload/v1643353663/uploaded_resources/One_no4yyb.gif'
                    alt='Logo'
                    style={{ height: '2.5rem' }}
                  />
                  <img
                    src='https://res.cloudinary.com/belong/image/upload/v1643353575/uploaded_resources/Two_yy5ee6.gif'
                    alt='Logo'
                    style={{ height: '2.5rem' }}
                  />
                </Box>
                <Typography
                  sx={{
                    borderTop: '2px solid grey',
                    fontWeight: 'bold',
                    letterSpacing: 2,
                    color: 'grey.600',
                    pt: 1,
                    textAlign: 'center',
                    fontSize: '0.875rem',
                  }}
                >
                  BELONG FOUNDERS
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', pl: 3, mb: 2 }}>
              <img src={leftLogo} alt='Logo' style={{ height: '2rem' }} />
            </Box>
          </StyledBox>
        </Box>
      </Box>
    </Container>
  );
};

export default DeviareInternshipCertificate;
