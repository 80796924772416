import React, { useState } from 'react';

import { Grid, Container, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ShowMoreText from 'react-show-more-text';

const Company = ({ batchData }) => {
  const batchUser = batchData.users;
  let allcompany = [];

  const [expand, setExpand] = useState(false);
  const onClick = () => {
    setExpand(!expand);
  };

  batchUser.forEach((user) => {
    if (user.mentor) {
      if (
        !allcompany.some((company) => {
          return user.company._id === company.company._id;
        })
      ) {
        allcompany.push({ company: user.company, mentor: user.mentor });
      }
    }
  });

  batchUser.forEach((user) => {
    if (user.company2) {
      if (
        !allcompany.some((company) => {
          return user.company2._id === company.company._id;
        })
      ) {
        allcompany.push({
          company: user.company2,
          mentor: user.mentor2 || user.mentor,
        });
      }
    }
  });

  return (
    <div>
      <Container maxWidth='xl'>
        <Grid container spacing={3}>
          {allcompany.map((data) => {
            return (
              <Grid key={data.company._id} item xs={12} sm={12} md={6}>
                <Card sx={{ maxWidth: 545 }}>
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {data.company.name}
                    </Typography>

                    <Typography component='div' gutterBottom>
                      {data.company.sectionContent.map((content, index) => {
                        return (
                          <Typography key={index} variant='body2'>
                            <ShowMoreText
                              lines={1}
                              more={<ExpandMore />}
                              less={<ExpandLess />}
                              onClick={onClick}
                              expanded={expand}
                              width={1000}
                            >
                              {content.contentToPublish}
                            </ShowMoreText>
                          </Typography>
                        );
                      })}
                    </Typography>
                    <Typography
                      sx={{ padding: '10px 5px' }}
                      gutterBottom
                    ></Typography>

                    <Stack
                      direction='row'
                      spacing={2}
                      sx={{ padding: '10px 5px' }}
                    >
                      <Avatar
                        alt='Remy Sharp'
                        src={data.mentor.imageUrl}
                        sx={{ width: 56, height: 56 }}
                      />
                      <Typography component='div'>
                        <Typography gutterBottom variant='subtitle1'>
                          {data.mentor.name}
                        </Typography>

                        {data.mentor.sectionContent.map((content, index) => {
                          return (
                            <Typography key={index} variant='body2'>
                              <ShowMoreText
                                lines={1}
                                more={<ExpandMore />}
                                less={<ExpandLess />}
                                onClick={onClick}
                                expanded={expand}
                                width={500}
                              >
                                {content.contentToPublish}
                              </ShowMoreText>
                            </Typography>
                          );
                        })}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default Company;
