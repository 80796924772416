import React, { useState, useRef } from 'react';

import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  IconButton,
  MenuItem,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useQuery, useQueryClient } from 'react-query';
import { Formik, Form } from 'formik';
import { API_URL } from '../../../links/API_LINKS';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { SelectInput } from '../../layouts/FormElement';
import { connect } from 'react-redux';

const AddCsm = ({ batch, partner }) => {
  const [serverResponse, setServerResponse] = useState();
  const formikRef = useRef();
  const [isEdit, setIsEdit] = useState(false);

  const partnerData = partner.partner.data;
  const isAdmin = partnerData.roles.includes('Partner Admin');

  const fetchCsm = async () => {
    const res = await axios.get(API_URL + '/dashboard/partner/team-members');
    return res;
  };

  const { status, data } = useQuery('fetchCsm', fetchCsm);
  const queryClient = useQueryClient();

  return (
    <div>
      {isEdit ? (
        <Box>
          {status === 'loading' && (
            <Box display='flex' justifyContent='center' alignItems='center'>
              <CircularProgress />
            </Box>
          )}
          {status === 'success' && (
            <Formik
              enableReinitialize={true}
              innerRef={formikRef}
              initialValues={{
                batchId: batch._id,
                partnerTeam: batch.partnerTeam.map((team) => {
                  return team._id;
                }),
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setServerResponse('');
                axios({
                  method: 'POST',
                  url: API_URL + '/dashboard/partner/link-members-to-batch',
                  data: values,
                })
                  .then(function (response) {
                    setServerResponse(response.data.message);
                    setSubmitting(false);
                    response.status === 200 && resetForm();
                    queryClient.invalidateQueries('fetchBatch');
                    setIsEdit(false);
                  })
                  .catch(function (response) {
                    setServerResponse('Error! Could not process your request.');
                    setSubmitting(false);
                  });
              }}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                  <Box>
                    {' '}
                    <SelectInput
                      multiple
                      fullWidth
                      name='partnerTeam'
                      label='Csm'
                    >
                      {data.data.data.map((admin) => {
                        return (
                          <MenuItem key={admin._id} value={admin._id}>
                            {admin.firstName}
                          </MenuItem>
                        );
                      })}
                    </SelectInput>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      p: 1,
                      m: 1,
                    }}
                  >
                    <Button type='button' onClick={() => setIsEdit(false)}>
                      Back
                    </Button>
                    <LoadingButton
                      size='small'
                      type='submit'
                      variant='contained'
                      loading={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      p: 1,
                      m: 1,
                    }}
                  >
                    {serverResponse && (
                      <Typography>{serverResponse}</Typography>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      ) : (
        <Grid container columnSpacing={2}>
          <Grid item xs={batch.partnerTeam.length ? 9 : 3}>
            {batch.partnerTeam.map((team) => {
              return <Typography key={team._id}>{team.firstName}</Typography>;
            })}
          </Grid>
          {isAdmin && (
            <Grid item xs={3}>
              <IconButton
                color='secondary'
                onClick={() => {
                  setIsEdit(true);
                }}
                aria-label='Edit'
              >
                {batch.partnerTeam.length ? <EditIcon /> : <AddIcon />}
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    partner: state.partner,
  };
};

export default connect(mapStateToProps)(AddCsm);
