import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import {
  TablePagination,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ProjectBody from './ProjectBody';
import StickyTableListHead from '../../../layouts/batch/StickyTableListHead';
import { applySortFilter, getComparator } from '../../../helper/TableShort';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import { ExportJsonCsv } from 'react-export-json-csv';

const StudentProjects = ({ allTasks, allSubmission }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [searched, setSearched] = useState('');
  const [showDetail, setShowDetail] = useState(false);
  const [userStatus, setUserStatus] = useState('All');

  const centuryTasks = allTasks
    .filter((item) => item.type === '21st Century Skills')
    .map((el) => {
      return {
        title: el.title,
        tasks: [{ taskId: el.tasks._id, projectId: el._id }],
        week: el.tasks.week,
      };
    });

  const companyTask = allTasks
    .filter((item) => item.type === 'Company Tasks')
    .map((el) => {
      return {
        title: el.title,
        tasks: [{ taskId: el.tasks._id, projectId: el._id }],
        week: el.tasks.week,
      };
    });

  const groupedData = companyTask.reduce((result, item) => {
    const week = item.week;

    if (!result[week]) {
      result[week] = {
        week,
        tasks: [],
      };
    }

    result[week].tasks = result[week].tasks.concat(item.tasks);

    return result;
  }, {});

  // Convert the grouped data object back into an array
  const groupedArray = Object.values(groupedData);

  const sortbyWeek = applySortFilter(
    groupedArray,
    getComparator('asc', 'week')
  );

  const taskNameUpdate = sortbyWeek.map((item, index) => {
    return { ...item, title: `Company Tasks - ${index + 1}` };
  });
  const finalData = centuryTasks.concat(taskNameUpdate);
  const finalAllTasks = applySortFilter(
    finalData,
    getComparator('asc', 'week')
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TABLE_HEAD = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
    },
    {
      id: 'taskSubmit',
      label: 'Submitted',
      align: 'center',
    },
    {
      id: 'submissionRate',
      label: 'Submission Rate',
      align: 'center',
    },
    {
      id: 'coach',
      label: 'Coach',
      align: 'center',
    },
    {
      id: 'company',
      label: 'Company',
      align: 'center',
    },
    {
      id: 'email',
      label: 'Email',
      align: 'center',
    },
  ];

  const allSubmissionArray = allSubmission.map((user) => {
    const taskSubmit = user.userTasks.filter((currentTask) => {
      return currentTask.tasksubmission?.solution;
    }).length;
    const submissionRate = user.userTasks.length
      ? Math.round((taskSubmit * 100) / user.userTasks.length)
      : 0;

    return {
      ...user,
      taskSubmit: taskSubmit,
      submissionRate: submissionRate,
      coach: user.coach.name,
      company: user.company.name,
      mentor: user.mentor.name || '',
      area: user.area.title || '',
    };
  });

  const [data, setData] = useState(allSubmissionArray);

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    const searchedValLower = searchedVal.toLowerCase();
    const filteredRows = allSubmissionArray.filter((row) => {
      return (
        row.name.toLowerCase().includes(searchedValLower) ||
        row.coach.toLowerCase().includes(searchedValLower) ||
        row.company.toLowerCase().includes(searchedValLower) ||
        row.area.toLowerCase().includes(searchedValLower) ||
        row.mentor.toLowerCase().includes(searchedValLower) ||
        row.email.toLowerCase().includes(searchedValLower)
      );
    });
    setData(filteredRows);
  };

  const activeUser = data.filter((user) => {
    if (userStatus === 'All') {
      return true;
    }
    return userStatus === user.status;
  });

  const filteredUsers = applySortFilter(
    activeUser,
    getComparator(order, orderBy)
  );

  const headers = [
    {
      key: 'Name',
      name: 'Name',
    },
    {
      key: 'Email',
      name: 'Email',
    },
    {
      key: 'Mentor',
      name: 'Mentor',
    },
    {
      key: 'Coach',
      name: 'Coach',
    },
    {
      key: 'Company',
      name: 'Company',
    },
    {
      key: 'TotalTasks',
      name: 'TotalTasks',
    },
    {
      key: 'SubmissionRate',
      name: 'SubmissionRate',
    },
  ];

  finalAllTasks.forEach((element, index) => {
    headers.push({
      key: `${element.title}-${index}`,
      name: element.title,
    });
    headers.push({
      key: `${element.title} -Total Score-${index}`,
      name: `${element.title} -Total Score`,
    });
    headers.push({
      key: `${element.title} -Coach Note-${index}`,
      name: `${element.title} -Coach Note`,
    });
  });

  const csvData = filteredUsers.map((item) => {
    let temp = {};
    finalAllTasks.forEach((element, index) => {
      const myTask = item.userTasks.filter((userTask) => {
        const projectIds = element.tasks.map((el) => el.projectId);
        const taskIds = element.tasks.map((el) => el.taskId);
        return (
          projectIds.includes(userTask.projectId) &&
          taskIds.includes(userTask.taskId)
        );
      })[0];
      const text = myTask
        ? myTask.tasksubmission?.submittedOn
          ? format(new Date(myTask.tasksubmission.submittedOn), 'do LLL yy')
          : 'Pending'
        : 'NA';
      temp[`${element.title}-${index}`] = text;

      // temp[`${element.title} - Problem Statement Analysis`] =
      //   myTask?.submission?.score?.problemStatementAnalysis;
      // temp[`${element.title} - Potential Solutions Evaluation`] =
      //   myTask?.submission?.score?.potentialSolutionsEvaluation;
      // temp[`${element.title} - Solution Strategy`] =
      //   myTask?.submission?.score?.solutionStrategy;
      // temp[`${element.title} - Challange Addressal`] =
      //   myTask?.submission?.score?.challangeAddressal;
      // temp[`${element.title} - Outcome Evaluation`] =
      //   myTask?.submission?.score?.outcomeEvaluation;
      temp[`${element.title} -Total Score-${index}`] =
        myTask?.tasksubmission?.score?.total;
      temp[`${element.title} -Coach Note-${index}`] =
        myTask?.tasksubmission?.coachNote;
    });
    return {
      Name: item.name,
      Email: item.email,
      Coach: item.coach,
      Company: item.company,
      Mentor: item.mentor.name,
      TotalTasks: item.userTasks?.length,
      SubmissionRate: `${item.submissionRate} %`,
      ...temp,
    };
  });

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Paper
          elevation={5}
          sx={{ width: 545, margin: 1, padding: 1, display: 'flex' }}
        >
          <TextField
            size='small'
            fullWidth
            label='Search Name Email Coach Company'
            value={searched}
            onChange={(e) => {
              requestSearch(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => requestSearch('')} edge='end'>
                    {searched ? <CloseIcon /> : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl sx={{ marginInline: 4 }} fullWidth size='small'>
            <InputLabel id='demo-simple-select-label'>User Status</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={userStatus}
              label='userStatus'
              onChange={(e) => setUserStatus(e.target.value)}
            >
              <MenuItem value='All'>All</MenuItem>
              <MenuItem value='Active'>Active</MenuItem>
              <MenuItem value='Inactive'>Inactive </MenuItem>
            </Select>
          </FormControl>
        </Paper>{' '}
        <ExportJsonCsv headers={headers} items={csvData}>
          <Button variant='outlined'>Submission</Button>
        </ExportJsonCsv>
      </Stack>
      <Button onClick={() => setShowDetail(!showDetail)}>
        {showDetail ? 'Hide Details' : 'Show Details'}
      </Button>
      <TableContainer sx={{ maxHeight: 600 }} component={Paper}>
        <Table
          stickyHeader
          sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
          aria-label='table'
          size='small'
        >
          <TableHead>
            <StickyTableListHead
              order={order}
              orderBy={orderBy}
              headLabel={showDetail ? TABLE_HEAD : [TABLE_HEAD[0]]}
              onRequestSort={handleRequestSort}
            />

            {finalAllTasks.map((task, index) => {
              return (
                <TableCell
                  key={index}
                  align='center'
                  sx={{ minWidth: 150, maxWidth: 200, fontSize: 12 }}
                >
                  {task.title}
                </TableCell>
              );
            })}
          </TableHead>
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <ProjectBody
                    key={row._id}
                    row={row}
                    allTasks={allTasks}
                    finalAllTasks={finalAllTasks}
                    showDetail={showDetail}
                  />
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 100]}
          component='div'
          count={activeUser.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default StudentProjects;
