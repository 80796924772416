import React, { useRef, useState } from 'react';
import { TextInput, SelectInput } from '../../layouts/FormElement';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, Button, MenuItem } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../../links/API_LINKS';
import axios from 'axios';
import Snackbar from '../../misc/Snackbar';

const AddTeam = ({ setShowAllAdmin }) => {
  const formikRef = useRef();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  return (
    <div>
      <Box
        sx={{
          p: 1,
          m: 1,
        }}
      >
        <Button
          onClick={() => setShowAllAdmin('ALL')}
          variant='outlined'
          startIcon={<ArrowBackIosIcon />}
        >
          Back
        </Button>
      </Box>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          name: '',
          email: '',
          roles: 'Partner Team',
          phone: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Name is required'),
          email: Yup.string()
            .email('Email must be a valid email address')
            .required('Email is required'),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const myData = { ...values, roles: [values.roles] };

          axios({
            method: 'POST',
            url: API_URL + '/admin/partner',
            data: myData,
          })
            .then(function (response) {
              setSubmitting(false);
              setSnackbarText('User has been added successfully');
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              response.data.status === 'success' && resetForm();
            })
            .catch(function (response) {
              setSubmitting(false);
              if (response.message.includes('409')) {
                setSnackbarText(`Sorry!, the email can't be added in the team`);
                setSnackbarSeverity('warning');
                setSnackbarOpen(true);
              } else {
                setSnackbarText('Something went wrong');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
              }
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              {' '}
              <Grid item xs={12} sm={6}>
                <TextInput fullWidth name='email' type='email' label='Email' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput fullWidth name='name' type='text' label='Name' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  name='phone'
                  type='text'
                  label='Phone No.'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput fullWidth name='roles' label='Role'>
                  <MenuItem value='Partner Team'>Team</MenuItem>
                  <MenuItem value='Partner Admin'>Admin</MenuItem>
                </SelectInput>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </div>
  );
};

export default AddTeam;
