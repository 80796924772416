import React from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

import { Stack, Box, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Profile = () => {
  const navigate = useNavigate();

  const UserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    designation: Yup.string().required('Designation is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      designation: '',
    },
    validationSchema: UserSchema,
    onSubmit: () => {
      navigate('/dashboard', { replace: true });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <Box sx={style}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} sx={{ my: 2 }}>
            <TextField
              fullWidth
              label='Name'
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              label='Designation'
              {...getFieldProps('designation')}
              error={Boolean(touched.designation && errors.designation)}
              helperText={touched.designation && errors.designation}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default Profile;
