import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, CircularProgress, Typography } from '@mui/material';

import { API_URL } from '../../../../links/API_LINKS';
import { useQuery } from 'react-query';
import axios from 'axios';

import EventList from './EventList';
import AttendanceHeader from './AttendanceHeader';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Index({ batchData }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchAttendance = async () => {
    const res = await axios.get(API_URL + '/batch/user-attendance', {
      params: { batchId: batchData._id },
    });
    return res;
  };

  const { status, data } = useQuery(
    `Attendance${batchData._id}`,
    fetchAttendance
  );

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
            >
              <Tab label='Event List' {...a11yProps(0)} />
              <Tab label='Student List' {...a11yProps(1)} />
            </Tabs>
          </Box>{' '}
          <TabPanel value={value} index={0}>
            <EventList
              attendance={data.data.data}
              allEvents={data.data.allEvents}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AttendanceHeader
              attendance={data.data.data}
              allEvents={data.data.allEvents}
            />
          </TabPanel>
        </Box>
      )}
    </>
  );
}
