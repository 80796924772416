import axios from 'axios';
import {
  FETCH_PARTNER_REQUEST,
  FETCH_PARTNER_SUCCESS,
  FETCH_PARTNER_FAILURE,
} from './partnerTypes';

import { API_URL } from '../../links/API_LINKS';

export const fetchPartnerDetails = () => {
  return (dispatch) => {
    dispatch(fetchPartnerRequest());
    axios
      .get(API_URL + '/admin/partner')
      .then((response) => {
        const partner = response.data;
        dispatch(fetchPartnerSuccess(partner));
      })
      .catch((error) => {
        dispatch(fetchPartnerFailure(error.message));
      });
  };
};

export const fetchPartnerRequest = () => {
  return {
    type: FETCH_PARTNER_REQUEST,
  };
};

export const fetchPartnerSuccess = (partner) => {
  return {
    type: FETCH_PARTNER_SUCCESS,
    payload: partner,
  };
};

export const fetchPartnerFailure = (error) => {
  return {
    type: FETCH_PARTNER_FAILURE,
    payload: error,
  };
};
