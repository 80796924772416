import axios from 'axios';
import React from 'react';
import { API_URL } from '../../../links/API_LINKS';
import { useQuery } from 'react-query';
import { Box, CircularProgress } from '@mui/material';
import SuperCapstoneTable from './SuperCapstoneTable';

const fetchSuperCapstoneSub = async () => {
  const res = await axios.get(API_URL + '/dashboard/partner/super-capstone', {
    params: { limit: 0, partnerId: '66b99825e72e50001534c8c0' },
  });
  return res;
};

const Index = () => {
  const { status, data } = useQuery(`superCapstoneSub`, fetchSuperCapstoneSub);

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <SuperCapstoneTable data={data?.data?.data || []} />
      )}
    </>
  );
};

export default Index;
