import React, { useState } from 'react';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import { TableRow, Chip } from '@mui/material';
import { format } from 'date-fns';
import moment from 'moment';

function SubEvent({ event }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        key={event._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          {event.events && (
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell align='center'>
          {event.events ? (
            <Chip
              variant='outlined'
              color='error'
              size='small'
              label='master event'
            />
          ) : (
            <Chip
              variant='outlined'
              color='secondary'
              size='small'
              label='event'
            />
          )}
        </TableCell>

        <TableCell align='left'>{event.title}</TableCell>
        <TableCell align='center'>
          {event.dateTime && format(new Date(event.dateTime), 'do LLL yy p')}
        </TableCell>
        <TableCell align='center'>
          {event.recordingUrl ? (
            <Button
              variant='contained'
              size='small'
              href={event.recordingUrl}
              target='_blank'
            >
              Recording Link
            </Button>
          ) : (
            event.link && (
              <Button
                variant='contained'
                size='small'
                href={event.link}
                target='_blank'
                disabled={moment().isAfter(
                  moment(event.dateTime).add(3, 'hours')
                )}
              >
                Join
              </Button>
            )
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {event.events &&
                    event.events.map((subEvent) => {
                      return (
                        <TableRow key={subEvent._id}>
                          <TableCell>{subEvent.title}</TableCell>
                          <TableCell>
                            {' '}
                            {subEvent.dateTime &&
                              format(
                                new Date(subEvent.dateTime),
                                'do LLL yy p'
                              )}
                          </TableCell>
                          <TableCell>
                            {' '}
                            {subEvent.recordingUrl ? (
                              <Button
                                variant='contained'
                                size='small'
                                href={subEvent.recordingUrl}
                                target='_blank'
                              >
                                Recording Link
                              </Button>
                            ) : (
                              subEvent.link && (
                                <Button
                                  variant='contained'
                                  size='small'
                                  href={subEvent.link}
                                  target='_blank'
                                  disabled={moment().isAfter(
                                    moment(subEvent.dateTime).add(3, 'hours')
                                  )}
                                >
                                  Join
                                </Button>
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default SubEvent;
