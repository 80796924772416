import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { TableRow, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import CertifacteBody from './CertifacteBody';

const CertifacteHeader = ({ batchData, userData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const rows = userData.map((user) => {
    const completionStatus =
      user.completionStatus === 'Not Applicable' && user.submissionRate === 100
        ? 'Completion'
        : user.completionStatus;
    return {
      name: user.name,
      email: user.email,
      completionStatus: completionStatus,
      userDetails: user,
    };
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <TableContainer sx={{ maxHeight: 500 }} component={Paper}>
        <Table
          stickyHeader
          sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
          aria-label='table'
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='left'>Email</TableCell>
              <TableCell align='center'> Certificate Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <CertifacteBody
                  key={row.email}
                  row={row}
                  batchData={batchData}
                />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default CertifacteHeader;
