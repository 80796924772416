import React from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';

import LeaderboardHeader from './LeaderboardHeader';

function Leaderboard({ batchData }) {
  const fetchLeaderboard = async () => {
    const res = await axios.get(API_URL + '/dashboard/partner/leaderboard', {
      params: { batchId: batchData._id },
    });
    return res;
  };

  const { status, data } = useQuery(
    `Leaderboard${batchData._id}`,
    fetchLeaderboard
  );

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && <LeaderboardHeader data={data.data.data} />}
    </div>
  );
}

export default Leaderboard;
