import React from 'react';
import {
  Grid,
  Container,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';

import AppCurrentVisits from '../../app/AppCurrentVisits';

import DataCard from '../../app/DataCard';
import TicketChart from './TicketChart';
import PeopleIcon from '@mui/icons-material/People';

const infoLighter = '#D0F2FF';
const infoDarker = '#04297A';

const TicketInfo = ({ batchData }) => {
  const fetchTicketOverview = async () => {
    const res = await axios.get(API_URL + '/ticket/overview', {
      params: {
        batch: batchData._id,
        batchStartDate: batchData.startDate,
        noOfWeeks: batchData.week,
      },
    });
    return res;
  };
  const { status, data } = useQuery(
    `TicketOverview${batchData._id}`,
    fetchTicketOverview
  );

  const ticketLabel = ['Closed', 'Open'];
  let ticketData = [];
  if (status === 'success') {
    ticketData = [data.data.closedTicket, data.data.openTicket];
  }

  return (
    <Container maxWidth='xl'>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}

      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <>
          {data.data.modifiedChartData.length ? (
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <AppCurrentVisits
                    heading='Ticket Overview'
                    label={ticketLabel}
                    data={ticketData}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <DataCard
                    total={data.data.avgFRT}
                    title='Avg first response time (In hours)'
                    icon={PeopleIcon}
                    bgColor={infoLighter}
                    textColor={infoDarker}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <DataCard
                    total={data.data.avgTAT}
                    title='Avg turn around time (In hours)'
                    icon={PeopleIcon}
                    bgColor={infoLighter}
                    textColor={infoDarker}
                  />
                </Grid>
              </Grid>

              <TicketChart
                data={data.data.modifiedChartData}
                batchData={batchData}
              />
            </Container>
          ) : (
            <Box>No Data</Box>
          )}
        </>
      )}
    </Container>
  );
};

export default TicketInfo;
