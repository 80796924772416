import React, { useState } from 'react';
import { filter } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import {
  TablePagination,
  Stack,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../../layouts/batch/TableListHead';
import LeaderboardBody from './LeaderboardBody';
import { ExportJsonCsv } from 'react-export-json-csv';

function LeaderboardHeader({ data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('engagementRate');
  const [userStatus, setUserStatus] = useState('All');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let rows = data.filter((user) => {
    if (userStatus === 'All') {
      return true;
    }
    return userStatus === user.status;
  });

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'engagementRate', label: 'Engagement Rate', alignRight: false },
    { id: 'attendanceRate', label: 'Attendance Rate', alignRight: false },
    { id: 'submissionRate', label: 'Submission Rate', alignRight: false },
    { id: 'quizScore', label: 'Quiz Score', alignRight: false },
    { id: 'stakeholdersScore', label: 'Stakeholder Score', alignRight: false },
    { id: 'completionStatus', label: 'Certificate Status', alignRight: false },
  ];
  const headers = [
    { key: 'name', name: 'Name' },
    { key: 'email', name: 'Email' },
    { key: 'coach', name: 'Coach' },
    { key: 'company', name: 'Company' },
    { key: 'mentor', name: 'Mentor' },
    { key: 'area', name: 'Area' },
    { key: 'engagementRate', name: 'Engagement Rate' },
    { key: 'attendanceRate', name: 'Attendance Rate' },
    { key: 'submissionRate', name: 'Submission Rate' },
    { key: 'quizScore', name: 'Quiz Score' },
    { key: 'stakeholdersScore', name: 'Stakeholder Score' },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={1}
      >
        <FormControl sx={{ marginInline: 4, width: 200 }} size='small'>
          <InputLabel id='demo-simple-select-label'>User Status</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={userStatus}
            label='userStatus'
            onChange={(e) => setUserStatus(e.target.value)}
          >
            <MenuItem value='All'>All</MenuItem>
            <MenuItem value='Active'>Active</MenuItem>
            <MenuItem value='Inactive'>Inactive</MenuItem>
          </Select>
        </FormControl>
        <ExportJsonCsv headers={headers} items={rows}>
          <Button variant='outlined'>Leaderboard Details</Button>
        </ExportJsonCsv>
      </Stack>
      <TableContainer sx={{ maxHeight: 500 }} component={Paper}>
        <Table
          stickyHeader
          sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
          aria-label='table'
          size='small'
        >
          <TableListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={rows.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <LeaderboardBody key={index} row={row} />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

export default LeaderboardHeader;
