import React, { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import CircularProgress from '@mui/material/CircularProgress';
import {
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { API_URL } from '../../links/API_LINKS';
import UsersBody from './UsersBody';
import { format } from 'date-fns/esm';
import TableListHead from '../layouts/batch/TableListHead';
import NewProfile from '../layouts/NewProfile';

const fetchUsers = async (params) => {
  const { limit, page, sort, order, searchRequestKeyword } = params;
  const res = await axios.get(API_URL + '/dashboard/partner/users', {
    params: { limit, page, sort, order, searchKeyword: searchRequestKeyword },
  });
  return res;
};

const debounce = (fn, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};

function AllUsers() {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchRequestKeyword, setSearchRequestKeyword] = useState('');
  const [showProfile, setShowProfile] = useState(null);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 10,
    sort: 'createdAt',
    order: 'desc',
  });

  const { status, data, isFetching } = useQuery(
    [`fetchUsers`, paginationData, searchRequestKeyword],
    () => fetchUsers({ ...paginationData, searchRequestKeyword }),
    { keepPreviousData: true }
  );

  const debouncedHandler = useCallback((value, duration = 1200) => {
    debounce(() => setSearchRequestKeyword(value), duration)();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc =
      paginationData.sort === property && paginationData.order === 'asc';
    setPaginationData({
      ...paginationData,
      sort: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const TABLE_HEAD = [
    { id: 'firstName', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'createdAt', label: 'Start Date' },
    { id: 'education', label: 'Course' },
    { id: 'belongscore', label: 'Belong Score' },
  ];

  const handleChangePage = (event, newPage) => {
    setPaginationData({
      ...paginationData,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      page: 0,
      limit: event.target.value,
    });
  };

  let rows = [];

  if (status === 'success') {
    rows = data?.data?.data?.map((user) => {
      const percentageScore =
        user.assessmentData?.percentageScore ||
        user.masterclassAssessment?.percentageScore;
      let score = 0;
      if (percentageScore) {
        score = parseInt(
          Object.values(percentageScore).reduce((a, b) => a + b, 0) / 4
        );
      }
      const education = user.education && user.education[0];
      const degree = education?.degree || 'NA';
      const course = education?.fieldOfStudy || '';
      const startDate = education?.startDate
        ? format(new Date(education?.startDate), 'LLL yy')
        : '';
      const endDate = education?.endDate
        ? format(new Date(education?.endDate), 'LLL yy')
        : '';

      return {
        _id: user._id,
        userId: user._id,
        applicantName: user.firstName || user.name || '',
        emailAddress: user.email,
        createdAt: format(new Date(user.createdAt), 'do LLLL yyyy'),
        lastLogin:
          user.lastLogin && format(new Date(user.lastLogin), 'do LLLL yyyy'),
        empScore: score,
        area: user.areas ? user.areas[0] : null,
        degree,
        course,
        startDate,
        endDate,
      };
    });
  }

  return (
    <>
      {showProfile ? (
        <NewProfile setShowProfile={setShowProfile} userId={showProfile} />
      ) : (
        <>
          <Paper elevation={5} sx={{ maxWidth: 345, margin: 1 }}>
            <TextField
              fullWidth
              label='Search user by name or email'
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
                debouncedHandler(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => {
                        setSearchKeyword('');
                        debouncedHandler('', 0);
                      }}
                      edge='end'
                    >
                      {searchKeyword ? <CloseIcon /> : <SearchIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Paper>
          {status === 'loading' && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              minHeight='50vh'
              minWidth='100vw'
            >
              <CircularProgress />
            </Box>
          )}
          {status === 'error' && <div>Error fetching data</div>}
          {status === 'success' && rows.length > 0 && (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 850, shadow: 2, padding: '6px' }}
                aria-label='table'
                size='small'
              >
                <TableListHead
                  order={paginationData.order}
                  orderBy={paginationData.sort}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {rows?.map((row) => (
                    <UsersBody
                      row={row}
                      key={row._id}
                      setShowProfile={setShowProfile}
                    />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[
                  ...new Set([paginationData.limit, 5, 10, 50]),
                ]}
                component='div'
                count={data?.data?.totalCount}
                rowsPerPage={paginationData.limit}
                page={paginationData.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{
                  disabled:
                    isFetching ||
                    data?.data?.page === data?.data?.totalPages - 1,
                }}
              />
            </TableContainer>
          )}
        </>
      )}
    </>
  );
}

export default AllUsers;
