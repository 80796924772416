import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow } from '@mui/material';
import { useQuery } from 'react-query';
import { API_URL } from '../../links/API_LINKS';
import axios from 'axios';

function UsersBody({ row, setShowProfile }) {
  const fetchUserBelongScore = async () => {
    const res = await axios.get(API_URL + '/user/onboard/new-belong-score', {
      params: { userId: row.userId },
    });
    return res;
  };

  const { status, data } = useQuery(
    `fetchUserBelongScore${row.userId}`,
    fetchUserBelongScore,
    { select: (data) => data?.data?.data }
  );

  return (
    <>
      <TableRow
        sx={{ cursor: 'pointer' }}
        key={row._id}
        onClick={() => setShowProfile(row._id)}
      >
        <TableCell align='left'>{row.applicantName}</TableCell>
        <TableCell align='left'>{row.emailAddress}</TableCell>
        <TableCell align='left'>{row.createdAt}</TableCell>
        <TableCell align='left'>
          {row.degree + ' ' + row.course} <br />
          {row.startDate + ' - ' + row.endDate}
        </TableCell>
        {/* <TableCell align='left'>{row.lastLogin}</TableCell> */}
        <TableCell align='center'>
          {status === 'success' && (
            <>{data ? `${data?.belongScore?.total} %` : 'NA'}</>
          )}
        </TableCell>{' '}
      </TableRow>
    </>
  );
}

export default UsersBody;
