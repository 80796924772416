import React from 'react';

import { Grid, Container, Typography, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import ReactReadMoreReadLess from 'react-read-more-read-less';

const Coaching = ({ batchData }) => {
  const batchUser = batchData.users;

  let allCoach = [];
  batchUser.forEach((user) => {
    if (
      !allCoach.some((coach) => {
        return user.coach._id === coach._id;
      })
    ) {
      allCoach.push(user.coach);
    }
    if (user.coach2) {
      if (
        !allCoach.some((coach) => {
          return user.coach2._id === coach._id;
        })
      ) {
        allCoach.push(user.coach2);
      }
    }
  });

  return (
    <div>
      <Container maxWidth='xl'>
        <Grid container spacing={3}>
          {allCoach.map((coach) => {
            return (
              <Grid key={coach._id} item xs={12} sm={12} md={3}>
                <Card sx={{ maxWidth: 345 }}>
                  <CardMedia
                    sx={{ padding: '4px 5px', backgroundSize: 'cover' }}
                    component='img'
                    height='140'
                    image={coach.coachImageUrl}
                    alt='image'
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant='subtitle1'
                      sx={{ textAlign: 'center' }}
                    >
                      {coach.name}
                    </Typography>
                    <Box gutterBottom>
                      {coach.sectionContent.map((content, index) => {
                        return (
                          <Typography key={index} variant='body2'>
                            <ReactReadMoreReadLess
                              charLimit={50}
                              readMoreText={'Read more ▼'}
                              readLessText={'Read less ▲'}
                              readMoreClassName='read-more-less--more'
                              readLessClassName='read-more-less--less'
                            >
                              {content.contentToPublish}
                            </ReactReadMoreReadLess>
                          </Typography>
                        );
                      })}
                    </Box>
                    <Typography gutterBottom></Typography>
                    <Typography variant='subtitle2'>
                      Specialisation :
                    </Typography>
                    {coach.areas.map((area, index) => {
                      return (
                        <Typography key={index} variant='body2'>
                          {area}
                        </Typography>
                      );
                    })}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default Coaching;
