import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { Box, Button, Stack, TableRow, Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { format } from 'date-fns';
import Modal from '@mui/material/Modal';
import htmlparser from 'html-react-parser';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  padding: 4,
  height: '80%',
  overflow: 'scroll',
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '10px',
};

const ProjectBody = ({ row, allTasks, showDetail, finalAllTasks }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    head: {
      backgroundColor: '#fff',
      minWidth: '100px',
    },
    tableContainer: {
      maxHeight: '400px',
    },
    cell: {
      minWidth: '120px',
      maxWidth: '130px',
    },
  }));

  const StickyTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#ddd',
      minWidth: '50px',
      left: 0,
      position: 'sticky',
      zIndex: theme.zIndex.appBar + 2,
    },
    body: {
      backgroundColor: '#ddd',
      minWidth: '50px',
      left: 0,
      position: 'sticky',
      align: 'center',
      zIndex: theme.zIndex.appBar + 1,
    },
  }))(TableCell);

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [solutionData, setSolution] = useState('');

  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <StickyTableCell>
          <TableCell
            component='th'
            scope='row'
            padding='none'
            align='left'
            className={classes.cell}
          >
            {row.name}
          </TableCell>
          {showDetail && (
            <>
              <TableCell align='center' className={classes.cell}>
                {row.taskSubmit}
              </TableCell>
              <TableCell align='center' className={classes.cell}>
                {row.taskSubmit ? (
                  <Typography>{row.submissionRate} %</Typography>
                ) : (
                  'NA'
                )}
              </TableCell>
              <TableCell align='center' className={classes.cell}>
                {row.coach}
              </TableCell>
              <TableCell align='center' className={classes.cell}>
                {row.company}
              </TableCell>
              <TableCell
                sx={{ wordBreak: 'break-word' }}
                align='center'
                className={classes.cell}
              >
                {row.email}
              </TableCell>
            </>
          )}
        </StickyTableCell>

        {finalAllTasks.map((task, index) => {
          const myTask = row.userTasks.filter((userTask) => {
            const projectIds = task.tasks.map((el) => el.projectId);
            const taskIds = task.tasks.map((el) => el.taskId);
            return (
              projectIds.includes(userTask.projectId) &&
              taskIds.includes(userTask.taskId)
            );
          })[0];
          return (
            <TableCell key={index} align='center'>
              {myTask ? (
                myTask.tasksubmission && myTask.tasksubmission.solution ? (
                  <Stack>
                    <Stack direction='row'>
                      <Button
                        onClick={() => {
                          setSolution(myTask.tasksubmission);
                          setOpen(true);
                        }}
                      >
                        View
                      </Button>
                      {myTask.tasksubmission?.score?.total ? (
                        <Typography mt={1} variant='caption'>
                          {myTask.tasksubmission?.score?.total}/100
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Stack>

                    <Typography variant='caption'>
                      {myTask.tasksubmission.submittedOn
                        ? format(
                            new Date(myTask.tasksubmission.submittedOn),
                            'do LLL yy'
                          )
                        : ''}
                    </Typography>
                  </Stack>
                ) : (
                  'Pending'
                )
              ) : (
                'NA'
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <Typography mb={1} variant='h6'>
            Solution :{' '}
          </Typography>
          {solutionData?.solution && (
            <Box p={2} sx={{ backgroundColor: 'primary.lighter' }}>
              {' '}
              {htmlparser(solutionData?.solution)}{' '}
            </Box>
          )}
          {solutionData?.score?.total && (
            <>
              <Typography my={2} variant='subtitle1'>
                Task Score : {solutionData?.score?.total}{' '}
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Typography>
                    Challenge Addressal :{' '}
                    {solutionData?.score?.challangeAddressal}/20{' '}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>
                    Outcome Evaluation :{' '}
                    {solutionData?.score?.outcomeEvaluation}/20{' '}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>
                    Problem Analysis :{' '}
                    {solutionData?.score?.problemStatementAnalysis}/20{' '}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>
                    Potential Solutions Evaluation :{' '}
                    {solutionData?.score?.potentialSolutionsEvaluation}/20{' '}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>
                    Strategy and Implementation :{' '}
                    {solutionData?.score?.solutionStrategy}/20{' '}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          {solutionData?.coachNote && (
            <Typography mt={2} variant='subtitle1'>
              Coach Note : {htmlparser(solutionData?.coachNote)}{' '}
            </Typography>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ProjectBody;
