import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import TableListHead from '../../../layouts/batch/TableListHead';
import { applySortFilter, getComparator } from '../../../helper/TableShort';

const AllProjects = ({ allTasks, data }) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('dueDateTime');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const TABLE_HEAD = [
    {
      id: 'title',
      label: 'Task Name',
      align: 'left',
    },
    {
      id: 'dueDateTime',
      label: 'Due On',
      align: 'left',
    },
    {
      id: 'submitted',
      label: 'Submitted',
      align: 'left',
    },
    {
      id: 'pending',
      label: 'Pending',
      align: 'left',
    },
    {
      id: 'submissionRate',
      label: 'Submission Rate',
      align: 'left',
    },
  ];
  const activeUser = data.filter((user) => user.status === 'Active');

  const projectData = allTasks.map((project) => {
    const eligibleUser = activeUser.filter((user) => {
      const isUserTask = user.userTasks.filter((currentTask) => {
        return (
          project._id === currentTask.projectId &&
          project.tasks._id === currentTask.taskId
        );
      }).length;
      return isUserTask;
    }).length;
    const submitted = activeUser.filter((user) => {
      const isUserSubmitted = user.userTasks.filter((currentTask) => {
        return (
          project._id === currentTask.projectId &&
          project.tasks._id === currentTask.taskId &&
          currentTask.tasksubmission?.solution
        );
      }).length;
      return isUserSubmitted;
    }).length;

    const pending = eligibleUser - submitted;
    const submissionRate = eligibleUser
      ? Math.round((submitted * 100) / eligibleUser)
      : 0;

    return {
      type: project.type,
      title: project.tasks?.title || project.title,
      week: project.tasks.week,
      dueDateTime: project.tasks.dueDateTime,
      submitted: submitted,
      total: eligibleUser,
      pending: pending,
      submissionRate: submissionRate,
    };
  });

  const companyTask = projectData.filter(
    (item) => item.type === 'Company Tasks'
  );

  const groupedTasks = {};
  companyTask.forEach((task) => {
    const { week, total, submitted, dueDateTime } = task;
    if (groupedTasks[week]) {
      groupedTasks[week].total += total;
      groupedTasks[week].submitted += submitted;
      groupedTasks[week].pending =
        groupedTasks[week].total - groupedTasks[week].submitted;
      groupedTasks[week].submissionRate = groupedTasks[week].total
        ? Math.round(
            (groupedTasks[week].submitted * 100) / groupedTasks[week].total
          )
        : 0;
    } else {
      groupedTasks[week] = {
        title: `Company Tasks week- ${week}`,
        week,
        dueDateTime,
        total,
        submitted,
        pending: total - submitted,
        submissionRate: total ? Math.round((submitted * 100) / total) : 0,
      };
    }
  });

  const groupedTasksArray = Object.values(groupedTasks);

  const sortbyWeek = applySortFilter(
    groupedTasksArray,
    getComparator('asc', 'week')
  );

  const taskNameUpdate = sortbyWeek.map((item, index) => {
    return { ...item, title: `Company Tasks - ${index + 1}` };
  });

  const centuryTasks = projectData.filter(
    (item) => item.type === '21st Century Skills'
  );
  const finalData = centuryTasks.concat(taskNameUpdate);
  const filteredUsers = applySortFilter(
    finalData,
    getComparator(order, orderBy)
  );

  return (
    <TableContainer sx={{ maxHeight: 450 }}>
      <Table
        sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
        stickyHeader
        aria-label='sticky table'
        size='small'
      >
        <TableListHead
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEAD}
          rowCount={projectData.length}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {filteredUsers.map((task, index) => (
            <TableRow key={index}>
              <TableCell align='left'>{task.title}</TableCell>
              <TableCell align='center'>
                {task.dueDateTime &&
                  format(new Date(task.dueDateTime), 'do LLL yy')}{' '}
              </TableCell>
              <TableCell align='center'>{task.submitted}</TableCell>
              <TableCell align='center'>{task.pending}</TableCell>
              <TableCell align='center'>
                {Math.round((task.submitted * 100) / task.total)} %
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AllProjects;
