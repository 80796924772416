import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Overview from './overview/Overview';
import UserDetails from './userDetails';
import Attendance from './attendance';
import Submissions from './submissions';
import Stakeholders from './stakeholders';
import Schedule from './schedule';
import Ticket from './ticket';
import FiibData from './fiibData';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../links/API_LINKS';
import ProjectSubmissions from './projectSubmissions';
import { partnerIds } from '../../../constants/common';
import Certificate from './certificate';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Index = ({ currentBatch, setCurrentBatch }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchCurrentBatch = async () => {
    const res = await axios.get(API_URL + '/batch', {
      params: { batchId: currentBatch._id },
    });
    return res;
  };

  const { status, data } = useQuery(currentBatch._id, fetchCurrentBatch);

  return (
    <Box sx={{ width: '100%' }}>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <>
          <Button
            size='large'
            variant='text'
            style={{ color: 'black' }}
            onClick={() => setCurrentBatch(null)}
          >
            <ArrowBackIcon />
          </Button>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
              variant='scrollable'
              scrollButtons='auto'
            >
              <Tab label='Overview' {...a11yProps(0)} />
              <Tab label='Users' {...a11yProps(1)} />
              <Tab label='Schedule' {...a11yProps(2)} />
              <Tab label='Attendance' {...a11yProps(3)} />
              <Tab label='Submission' {...a11yProps(4)} />
              <Tab label='Stakeholder' {...a11yProps(5)} />
              {data.data.data[0].enableQueryManagement && (
                <Tab label='Query' {...a11yProps(6)} value={6} />
              )}
              {data.data.data[0].partner === '612708fa44e06a0023bd8c9b' && (
                <Tab label='Belong Score' {...a11yProps(7)} value={7} />
              )}
              {/* Belong Score tab is only for Fiib partner  */}
              {data.data.data[0].partner === partnerIds.heroVired &&
                data.data.data[0]?.projects?.length &&
                data.data.data[0].status === 'Completed' && (
                  <Tab label='Certificate' {...a11yProps(8)} value={8} />
                )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Overview batchData={data.data.data[0]} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UserDetails batchData={data.data.data[0]} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Schedule batchData={data.data.data[0]} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Attendance batchData={data.data.data[0]} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            {data.data.data[0]?.projects &&
            data.data.data[0]?.projects.length ? (
              <ProjectSubmissions batchData={data.data.data[0]} />
            ) : (
              <Submissions batchData={data.data.data[0]} />
            )}
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Stakeholders batchData={data.data.data[0]} />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Ticket batchData={data.data.data[0]} />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <FiibData batchData={data.data.data[0]} />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <Certificate batchData={data.data.data[0]} />
          </TabPanel>
        </>
      )}
    </Box>
  );
};

export default Index;
