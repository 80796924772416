import {
  FETCH_PARTNER_REQUEST,
  FETCH_PARTNER_SUCCESS,
  FETCH_PARTNER_FAILURE,
} from './partnerTypes';

const initialState = {
  loading: false,
  partner: null,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PARTNER_SUCCESS:
      return {
        loading: false,
        partner: action.payload,
        error: '',
      };
    case FETCH_PARTNER_FAILURE:
      return {
        loading: false,
        partner: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
