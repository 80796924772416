import React from 'react';
import AppWebsiteVisits from '../../app/AppWebsiteVisits';
import { Box } from '@mui/material';

const TicketChart = ({ data, batchData }) => {
  function currentWeek(startDate) {
    const dt2 = new Date(startDate);
    const dt1 = new Date(batchData.startDate);

    const totalDays = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
    const myCurrentWeek = Math.ceil((totalDays + 1) / 7);
    return myCurrentWeek;
  }

  const dt1 = new Date(batchData.startDate);
  const dt2 = new Date();

  const totalDays = Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
  const batchCurentWeek = Math.ceil((totalDays + 1) / 7);

  const maxDataWeek = Math.min(batchCurentWeek, batchData.week);

  function averageTAT(arr) {
    const tatArr = arr.filter((ticket) => {
      return ticket.TAT !== null;
    });
    const average =
      tatArr.reduce((total, next) => total + next.TAT, 0) / tatArr.length;
    return average || 0;
  }
  function averageFRT(arr) {
    const tatArr = arr.filter((ticket) => {
      return ticket.FRT !== null;
    });
    const average =
      tatArr.reduce((total, next) => total + next.FRT, 0) / tatArr.length;
    return average || 0;
  }
  let chartData = [];
  for (let i = 1; i <= maxDataWeek; i++) {
    const weekticket = data.filter((ticket) => {
      const ticketWeek = currentWeek(ticket.openOn);
      return ticketWeek === i;
    });
    const avgTAT = averageTAT(weekticket);
    const avgFRT = averageFRT(weekticket);

    chartData.push({
      week: 'Week ' + i,
      totalTicket: weekticket.length,
      avgFRT: avgFRT,
      avgTAT: avgTAT,
    });
  }
  const CHART_DATA = [
    {
      name: 'Total Tickets',
      type: 'column',
      data: chartData.map((a) => a.totalTicket),
    },
    {
      name: 'Average first response time',
      type: 'area',
      data: chartData.map((a) => Math.round((a.avgFRT * 100) / 100)),
    },
    {
      name: 'Average turn around time,',
      type: 'line',
      data: chartData.map((a) => Math.round((a.avgFRT * 100) / 100)),
    },
  ];

  const labels = chartData.map((a) => a.week);
  const chart = { values: CHART_DATA, labels: labels };

  return (
    <Box sx={{ mt: 2 }}>
      <AppWebsiteVisits chart={chart} title='Overview' />
    </Box>
  );
};

export default TicketChart;
