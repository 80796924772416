import React from 'react';
import { Grid, Container, Skeleton } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

// components
import Page from '../../../Page';
import DataCard from '../../app/DataCard';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
// import TotalUser from './TotalUser';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import AvgBelongScore from './AvgBelongScore';
import { partnerIds } from '../../../../constants/common';
import AvgDeviareScore from './AvgDeviareScore';

const infoLighter = '#D0F2FF';
const infoDarker = '#04297A';
const warningLighter = '#FFF7CD';
const warningDarker = '#7A4F01';
const primaryLighter = '#C8FACD';
const primaryDarker = '#005249';
const errorLighter = '#FFE7D9';
const errorDarker = '#7A0C2E';

const Overview = ({ batchData }) => {
  const isDeviare = batchData.partner === partnerIds.deviare;
  const fetchBatchOverview = async () => {
    const res = await axios.get(
      API_URL + '/dashboard/partner/batch-attendance-submission-rate',
      {
        params: {
          batchId: batchData._id,
        },
      }
    );
    return res;
  };

  const { status, data } = useQuery(
    `BatchOverview${batchData._id}`,
    fetchBatchOverview
  );

  return (
    <Page title='Dashboard | Belong'>
      <Container maxWidth='xl'>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <DataCard
              total={batchData.users.length}
              title='Users'
              icon={PeopleIcon}
              bgColor={infoLighter}
              textColor={infoDarker}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <DataCard
              total={batchData.events.length}
              title='Events'
              icon={EmojiEventsIcon}
              bgColor={warningLighter}
              textColor={warningDarker}
            />
          </Grid>

          {batchData.tasks?.length > 0 && (
            <Grid item xs={12} sm={12} md={4}>
              <DataCard
                total={batchData.tasks?.length}
                title='Assignments'
                icon={AssignmentIcon}
                bgColor={primaryLighter}
                textColor={primaryDarker}
              />
            </Grid>
          )}

          {status === 'loading' && (
            <>
              <Grid item xs={12} sm={12} md={4}>
                <Skeleton
                  sx={{ bgcolor: 'grey.200' }}
                  style={{ borderRadius: '14px' }}
                  animation='wave'
                  variant='rectangular'
                  width='100%'
                  height={220}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Skeleton
                  sx={{ bgcolor: 'grey.200' }}
                  style={{ borderRadius: '14px' }}
                  animation='wave'
                  variant='rectangular'
                  width='100%'
                  height={220}
                />
              </Grid>
            </>
          )}

          {status === 'success' && (
            <>
              {data.data.data.attendanceRate > 0 && (
                <Grid item xs={12} sm={12} md={4}>
                  <DataCard
                    total={`${data.data.data.attendanceRate} %`}
                    title='Attendance Rate'
                    icon={EmojiEventsIcon}
                    bgColor={warningLighter}
                    textColor={warningDarker}
                  />
                </Grid>
              )}
              {data.data.data.submissionRate > 0 && (
                <Grid item xs={12} sm={12} md={4}>
                  <DataCard
                    total={`${data.data.data.submissionRate} %`}
                    title='Submission Rate'
                    icon={AssignmentIcon}
                    bgColor={errorLighter}
                    textColor={errorDarker}
                  />
                </Grid>
              )}
              {data.data.data.nps && data.data.data.nps > 0 && (
                <Grid item xs={12} sm={12} md={4}>
                  <DataCard
                    total={`${data.data.data.nps} %`}
                    title='NPS'
                    icon={PeopleIcon}
                    bgColor={infoLighter}
                    textColor={infoDarker}
                  />
                </Grid>
              )}
            </>
          )}
          {batchData.projects?.length > 0 && (
            <Grid item xs={12} sm={12} md={4}>
              {isDeviare ? (
                <AvgDeviareScore batchData={batchData} />
              ) : (
                <AvgBelongScore batchData={batchData} />
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default Overview;
