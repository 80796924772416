//AvgDeviareScore
import React from 'react';
import { Skeleton } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import DataCard from '../../app/DataCard';
import { partnerIds } from '../../../../constants/common';

const primaryLighter = '#C8FACD';
const primaryDarker = '#005249';

const AvgDeviareScore = ({ batchData }) => {
  const isDeviare = batchData.partner === partnerIds.deviare;
  const fetchLeaderboard = async () => {
    const res = await axios.get(
      API_URL + '/dashboard/partner/deviare-leaderboard',
      {
        params: { batchId: batchData._id },
      }
    );
    return res;
  };

  const { status, data } = useQuery(
    `DeviareLeaderboard${batchData._id}`,
    fetchLeaderboard
  );

  let avgScore = 0;

  if (status === 'success' && data.data.data) {
    let totalScore = 0;
    let totalUser = 0;
    data.data.data.forEach((user) => {
      if (user.employabilityScore && user.status === 'Active') {
        totalScore = totalScore + user.employabilityScore;
        totalUser = totalUser + 1;
      }
    });
    if (totalUser) {
      avgScore = totalScore / totalUser;
    }
  }

  return (
    <>
      {status === 'loading' && (
        <>
          <Skeleton
            sx={{ bgcolor: 'grey.200' }}
            style={{ borderRadius: '14px' }}
            animation='wave'
            variant='rectangular'
            width='100%'
            height={220}
          />
        </>
      )}
      {status === 'success' && avgScore > 0 && (
        <DataCard
          total={`${avgScore.toFixed(2)} %`}
          title={`Avg ${isDeviare ? 'Employability' : 'Belong'} Score`}
          icon={EmojiEventsIcon}
          bgColor={primaryLighter}
          textColor={primaryDarker}
        />
      )}
    </>
  );
};

export default AvgDeviareScore;
