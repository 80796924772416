import PropTypes from 'prop-types';
// material
import { visuallyHidden } from '@mui/utils';
import { Box, TableCell, TableSortLabel } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';

// ----------------------------------------------------------------------

StickyTableListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  // rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  onRequestSort: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  head: {
    backgroundColor: '#fff',
    minWidth: '120px',
    maxWidth: '130px',
  },
  tableContainer: {
    maxHeight: '400px',
  },
  cell: {
    minWidth: '200px',
  },
}));

const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#ddd',
    left: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2,
  },
}))(TableCell);

export default function StickyTableListHead({
  order,
  orderBy,
  headLabel,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const classes = useStyles();
  return (
    <StickyTableCell className={classes.head}>
      {headLabel.map((headCell) => (
        <TableCell
          className={classes.head}
          key={headCell.id}
          align={headCell.align}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <TableSortLabel
            hideSortIcon
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : 'asc'}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box sx={{ ...visuallyHidden }}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
    </StickyTableCell>
  );
}
