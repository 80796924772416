export const capstonePartner = [
  '6319e62c68c34c0016a7a85d', // careerera
  '612708fa44e06a0023bd8c9b', // FIIB
  '640ec8835ecc94001441e744', // Console Flare
];

export const partnerIds = {
  heroVired: '620e2a2f7f497a001637678b',
  deviare: '610a7ca8d3c51a1458d005ce',
};
