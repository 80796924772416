import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow } from '@mui/material';
import { Typography, Link } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { UPLOAD_OR_DOWNLOAD_URL } from '../../../../links/API_LINKS';
import { format } from 'date-fns';

function SubmissionBody({ row, allTasks, showDetail }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    head: {
      backgroundColor: '#fff',
      minWidth: '100px',
    },
    tableContainer: {
      maxHeight: '400px',
    },
    cell: {
      minWidth: '120px',
      maxWidth: '130px',
    },
  }));

  const StickyTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#ddd',
      minWidth: '50px',
      left: 0,
      position: 'sticky',
      zIndex: theme.zIndex.appBar + 2,
    },
    body: {
      backgroundColor: '#ddd',
      minWidth: '50px',
      left: 0,
      position: 'sticky',
      align: 'center',
      zIndex: theme.zIndex.appBar + 1,
    },
  }))(TableCell);

  const classes = useStyles();

  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <StickyTableCell>
          <TableCell
            component='th'
            scope='row'
            padding='none'
            align='left'
            className={classes.cell}
          >
            {row.name}
          </TableCell>
          {showDetail && (
            <>
              <TableCell align='center' className={classes.cell}>
                {row.taskSubmit}
              </TableCell>
              <TableCell align='center' className={classes.cell}>
                {row.taskSubmit ? (
                  <Typography>{row.submissionRate} %</Typography>
                ) : (
                  'NA'
                )}
              </TableCell>
              <TableCell align='center' className={classes.cell}>
                {row.coach}
              </TableCell>
              <TableCell align='center' className={classes.cell}>
                {row.company}
              </TableCell>
              <TableCell
                sx={{ wordBreak: 'break-word' }}
                align='center'
                className={classes.cell}
              >
                {row.email}
              </TableCell>
            </>
          )}
        </StickyTableCell>

        {allTasks.map((task) => {
          const myTask = row.tasks.filter((userTask) => {
            return userTask._id === task._id;
          })[0];
          return (
            <TableCell key={task._id} align='center'>
              {myTask ? (
                myTask.submission && myTask.submission.submittedFile ? (
                  <>
                    <Link
                      href={
                        UPLOAD_OR_DOWNLOAD_URL +
                        '/' +
                        myTask.submission.submittedFile
                      }
                      target='_blank'
                      rel='noreferrer'
                    >
                      <DownloadForOfflineIcon />
                    </Link>
                    <Typography>
                      {myTask.submission.submissionTime
                        ? format(
                            new Date(myTask.submission.submissionTime),
                            'do LLL yy'
                          )
                        : ''}
                    </Typography>
                  </>
                ) : (
                  'Pending'
                )
              ) : (
                'NA'
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
}

export default SubmissionBody;
