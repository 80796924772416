import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import { UPLOAD_OR_DOWNLOAD_URL } from '../../../../links/API_LINKS';
import {
  TablePagination,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import SubmissionBody from './SubmissionBody';
import StickyTableListHead from '../../../layouts/batch/StickyTableListHead';
import { applySortFilter, getComparator } from '../../../helper/TableShort';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
// import CsvDownloadButton from 'react-json-to-csv';
import { connect } from 'react-redux';
import { ExportJsonCsv } from 'react-export-json-csv';

function StudentSubmission({ allTasks, allSubmission, partner }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [searched, setSearched] = useState('');
  const [showDetail, setShowDetail] = useState(false);
  const [userStatus, setUserStatus] = useState('All');

  const partnerData = partner?.partner?.data;
  const isTeamAdmin = partnerData.roles.some((role) => {
    return ['Admin', 'Team'].includes(role);
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TABLE_HEAD = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
    },
    {
      id: 'taskSubmit',
      label: 'Submitted',
      align: 'center',
    },
    {
      id: 'submissionRate',
      label: 'Submission Rate',
      align: 'center',
    },
    {
      id: 'coach',
      label: 'Coach',
      align: 'center',
    },
    {
      id: 'company',
      label: 'Company',
      align: 'center',
    },
    {
      id: 'email',
      label: 'Email',
      align: 'center',
    },
  ];
  const allSubmissionArray = allSubmission.map((item) => {
    const taskSubmit = item.tasks.filter((task) => {
      return task.submission && task.submission.submittedFile;
    }).length;

    const submissionRate = item.tasks.length
      ? Math.round((taskSubmit * 100) / item.tasks.length)
      : 0;
    return {
      ...item,
      taskSubmit: taskSubmit,
      submissionRate: submissionRate,
      coach: item.coach.name,
      company: item.company.name,
    };
  });

  const [data, setData] = useState(allSubmissionArray);
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    const searchedValLower = searchedVal.toLowerCase();
    const filteredRows = allSubmissionArray.filter((row) => {
      return (
        row.name.toLowerCase().includes(searchedValLower) ||
        row.coach.toLowerCase().includes(searchedValLower) ||
        row.company.toLowerCase().includes(searchedValLower) ||
        row.email.toLowerCase().includes(searchedValLower)
      );
    });
    setData(filteredRows);
  };
  const activeUser = data.filter((user) => {
    if (userStatus === 'All') {
      return true;
    }
    return userStatus === user.status;
  });

  const headers = [
    {
      key: 'Name',
      name: 'Name',
    },
    {
      key: 'Email',
      name: 'Email',
    },
    {
      key: 'Mentor',
      name: 'Mentor',
    },
    {
      key: 'Coach',
      name: 'Coach',
    },
    {
      key: 'Company',
      name: 'Company',
    },
    {
      key: 'TotalTasks',
      name: 'TotalTasks',
    },
    {
      key: 'SubmissionRate',
      name: 'SubmissionRate',
    },
  ];

  const filteredUsers = applySortFilter(
    activeUser,
    getComparator(order, orderBy)
  );
  const csvData = filteredUsers.map((item) => {
    let temp = {};
    allTasks.forEach((element, index) => {
      const myTask = item.tasks.filter((userTask) => {
        return userTask._id === element._id;
      })[0];
      const text = myTask
        ? myTask.submission && myTask.submission.submittedFile
          ? UPLOAD_OR_DOWNLOAD_URL + '/' + myTask.submission.submittedFile
          : 'Pending'
        : 'NA';
      temp[`${element.title}-${index}`] = text;
      if (isTeamAdmin) {
        temp[`${element.title} - Problem Statement Analysis`] =
          myTask?.submission?.score?.problemStatementAnalysis;
        temp[`${element.title} - Potential Solutions Evaluation`] =
          myTask?.submission?.score?.potentialSolutionsEvaluation;
        temp[`${element.title} - Solution Strategy`] =
          myTask?.submission?.score?.solutionStrategy;
        temp[`${element.title} - Challange Addressal`] =
          myTask?.submission?.score?.challangeAddressal;
        temp[`${element.title} - Outcome Evaluation`] =
          myTask?.submission?.score?.outcomeEvaluation;
        temp[`${element.title} -Total Score-${index}`] =
          myTask?.submission?.score?.total;
      }
    });
    return {
      Name: item.name,
      Email: item.email,
      Coach: item.coach,
      Company: item.company,
      Mentor: item.mentor.name,
      TotalTasks: item.tasks.length,
      SubmissionRate: `${item.submissionRate} %`,
      ...temp,
    };
  });

  allTasks.forEach((element, index) => {
    headers.push({
      key: `${element.title}-${index}`,
      name: element.title,
    });

    if (isTeamAdmin) {
      headers.push({
        key: `${element.title} -Total Score-${index}`,
        name: `${element.title} -Total Score`,
      });
    }
  });
  return (
    <>
      {' '}
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Paper
          elevation={5}
          sx={{ width: 545, margin: 1, padding: 1, display: 'flex' }}
        >
          <TextField
            fullWidth
            size='small'
            label='Search Name Email Coach Company'
            value={searched}
            onChange={(e) => {
              requestSearch(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => requestSearch('')} edge='end'>
                    {searched ? <CloseIcon /> : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl sx={{ marginInline: 4 }} fullWidth size='small'>
            <InputLabel id='demo-simple-select-label'>User Status</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={userStatus}
              label='userStatus'
              onChange={(e) => setUserStatus(e.target.value)}
            >
              <MenuItem value='All'>All</MenuItem>
              <MenuItem value='Active'>Active</MenuItem>
              <MenuItem value='Inactive'>Inactive </MenuItem>
            </Select>
          </FormControl>
        </Paper>{' '}
        <ExportJsonCsv headers={headers} items={csvData}>
          <Button variant='outlined'>Submission</Button>
        </ExportJsonCsv>
      </Stack>
      <Button onClick={() => setShowDetail(!showDetail)}>
        {showDetail ? 'Hide Details' : 'Show Details'}
      </Button>
      <TableContainer sx={{ maxHeight: 600 }} component={Paper}>
        <Table
          stickyHeader
          sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
          aria-label='table'
          size='small'
        >
          <TableHead>
            <StickyTableListHead
              order={order}
              orderBy={orderBy}
              headLabel={showDetail ? TABLE_HEAD : [TABLE_HEAD[0]]}
              onRequestSort={handleRequestSort}
            />

            {allTasks.map((task) => {
              return (
                <TableCell
                  key={task._id}
                  align='center'
                  sx={{ minWidth: 150, maxWidth: 200, fontSize: 12 }}
                >
                  {task.title}
                </TableCell>
              );
            })}
          </TableHead>
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <SubmissionBody
                  key={row._id}
                  row={row}
                  allTasks={allTasks}
                  showDetail={showDetail}
                />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 100]}
          component='div'
          count={activeUser.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    partner: state.partner,
  };
};
export default connect(mapStateToProps)(StudentSubmission);
