import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Auth } from 'aws-amplify';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { API_URL } from '../../../links/API_LINKS';

// ----------------------------------------------------------------------

const ConfirmationForm = ({ enteredEmail, setConfirmed, userId }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState();

  const ConfirmationSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      password: '',
    },
    validationSchema: ConfirmationSchema,
    onSubmit: async () => {
      try {
        await Auth.forgotPasswordSubmit(
          enteredEmail,
          values.code,
          values.password
        );
        if (userId) {
          await axios({
            method: 'PUT',
            url: API_URL + '/admin/updateProfileStage',
            data: { userId, passwordReset: 'success' },
          });
        }
        setConfirmed(true);
      } catch (error) {
        setFeedbackMessage(error.message);
      }
    },
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <>
        <Stack sx={{ mb: 5 }}>
          <Typography variant='h4' gutterBottom>
            Email Confirmation!
          </Typography>
          <Typography variant='caption' sx={{ color: 'text.secondary' }}>
            If an account exists with{' '}
            <span className='font-semibold'>({enteredEmail})</span>,an email has
            been sent with the confirmation code.
          </Typography>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ my: 2 }}>
              <TextField
                fullWidth
                type='text'
                label='code'
                {...getFieldProps('code')}
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code}
              />
              <TextField
                fullWidth
                autoComplete='current-password'
                type={showPassword ? 'text' : 'password'}
                label='New Password'
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleShowPassword} edge='end'>
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
            >
              Reset Password
            </LoadingButton>
          </Form>
          <Stack>{feedbackMessage}</Stack>
        </FormikProvider>
      </>
    </>
  );
};

export default ConfirmationForm;
