import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow } from '@mui/material';
import { Typography, Button, styled, Link, Stack } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { UPLOAD_OR_DOWNLOAD_URL } from '../../../../links/API_LINKS';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 260,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

function TimeSheetbody({ row, headerWeek }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    head: {
      backgroundColor: '#fff',
      minWidth: '100px',
    },
    tableContainer: {
      maxHeight: '400px',
    },
    cell: {
      minWidth: '120px',
      maxWidth: '120px',
    },
    nameColor: {
      color: '',
    },
  }));

  const StickyTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#ddd',
      minWidth: '50px',
      left: 0,
      position: 'sticky',
      zIndex: theme.zIndex.appBar + 2,
    },
    body: {
      backgroundColor: '#ddd',
      minWidth: '50px',
      left: 0,
      position: 'sticky',
      align: 'center',
      zIndex: theme.zIndex.appBar + 1,
    },
  }))(TableCell);

  const classes = useStyles();

  const totalHours = row.timeSheet.reduce(function (p, c) {
    return p + Number(c.totalWeeklyHours || 0);
  }, 0);

  const commitRate = Math.round((totalHours * 100) / (headerWeek.length * 40));

  const pending = headerWeek.length - row.timeSheet.length;

  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <StickyTableCell>
          <TableCell
            component='th'
            scope='row'
            padding='none'
            align='center'
            className={classes.cell}
          >
            <Typography variant='subtitle2' className={classes.nameColor}>
              {row.name}
            </Typography>
          </TableCell>

          <TableCell align='center' className={classes.cell}>
            {totalHours}
          </TableCell>
          <TableCell align='center' className={classes.cell}>
            {commitRate} %
          </TableCell>
          <TableCell align='center' className={classes.cell}>
            {pending}
          </TableCell>
        </StickyTableCell>

        {headerWeek.map((week) => {
          const myTimesheet = row.timeSheet.filter((myTS) => {
            return myTS.week === week;
          })[0];
          return (
            <TableCell key={week} align='center' className={classes.cell}>
              {myTimesheet ? (
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                >
                  <HtmlTooltip
                    title={
                      <>
                        {myTimesheet.weeklyReport
                          ? myTimesheet.weeklyReport.map((report, index) => {
                              return (
                                <Typography key={index} color='inherit'>
                                  {report.eventCode} : {report.hours}{' '}
                                </Typography>
                              );
                            })
                          : 'No Report'}
                      </>
                    }
                  >
                    <Button>{myTimesheet.totalWeeklyHours}</Button>
                  </HtmlTooltip>
                  {myTimesheet.fileUrl && (
                    <Link
                      href={UPLOAD_OR_DOWNLOAD_URL + '/' + myTimesheet.fileUrl}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Typography mt={1}>
                        <DownloadForOfflineIcon />
                      </Typography>
                    </Link>
                  )}
                </Stack>
              ) : (
                'NA'
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
}

export default TimeSheetbody;
