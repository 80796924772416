import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';

import { withStyles, makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import { ExportJsonCsv } from 'react-export-json-csv';
import TimeSheetbody from './TimeSheetbody';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  head: {
    backgroundColor: '#fff',
    minWidth: '50px',
  },
  tableContainer: {
    maxHeight: '400px',
  },
  cell: {
    minWidth: '120px',
    maxWidth: '120px',
  },
}));

const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#ddd',
    left: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2,
  },
}))(TableCell);

function TimeSheet({ batchData }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchTimeSheet = async () => {
    const res = await axios.get(API_URL + '/timesheet/batch-timesheet', {
      params: { batchId: batchData._id },
    });
    return res;
  };

  const { status, data } = useQuery(
    `TimeSheet${batchData._id}`,
    fetchTimeSheet
  );

  //const headerWeek = [11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const classes = useStyles();

  const headers = [
    { key: 'Name', name: 'Name' },
    { key: 'Email', name: 'Email' },
    { key: 'totalHours', name: 'Total Hours' },
    { key: 'commitRate', name: 'Commit Rate' },
    { key: 'pendingTimesheets', name: 'Pending Timesheets' },
  ];
  const csvData = [];
  if (status === 'success' && data?.data?.data) {
    data?.data?.data.headerWeek.forEach((element) => {
      headers.push({ key: `week${element}`, name: `Week ${element} Total` });
      headers.push({
        key: `week${element}-Mentor Meeting`,
        name: `Week ${element} Mentor Meeting`,
      });
      headers.push({
        key: `week${element}-Coach Connect`,
        name: `Week ${element} Coach Connect`,
      });
      headers.push({
        key: `week${element}-Internship Work`,
        name: `Week ${element} Internship Work`,
      });
      headers.push({
        key: `week${element}-Self Learning`,
        name: `Week ${element} Self Learning`,
      });
      headers.push({
        key: `week${element}-Career Cafe`,
        name: `Week ${element} Career Cafe`,
      });
    });
    const headerWeek = data?.data?.data.headerWeek;
    data.data.data.users.forEach((user) => {
      const totalHours = user.timeSheet.reduce(function (p, c) {
        return p + Number(c.totalWeeklyHours || 0);
      }, 0);
      const commitRate = Math.round(
        (totalHours * 100) / (headerWeek.length * 40)
      );
      const pending = headerWeek.length - user.timeSheet.length;
      const userTempObj = {
        Name: user.name,
        Email: user.email,
        totalHours: totalHours,
        commitRate: `${commitRate} %`,
        pendingTimesheets: pending,
      };
      user.timeSheet.forEach((timeSheet) => {
        userTempObj[`week${timeSheet.week}`] = timeSheet.totalWeeklyHours;

        userTempObj[`week${timeSheet.week}-Mentor Meeting`] =
          timeSheet?.weeklyReport[0]?.hours;
        userTempObj[`week${timeSheet.week}-Coach Connect`] =
          timeSheet?.weeklyReport[1]?.hours;
        userTempObj[`week${timeSheet.week}-Internship Work`] =
          timeSheet?.weeklyReport[2]?.hours;
        userTempObj[`week${timeSheet.week}-Self Learning`] =
          timeSheet?.weeklyReport[3]?.hours;
        userTempObj[`week${timeSheet.week}-Career Cafe`] =
          timeSheet?.weeklyReport[4]?.hours;
      });
      csvData.push(userTempObj);
    });
  }

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}

      {status === 'success' && (
        <>
          <Stack
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={2}
          >
            {' '}
            <ExportJsonCsv headers={headers} items={csvData}>
              <Button variant='outlined'>TimeSheet</Button>
            </ExportJsonCsv>
          </Stack>

          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
              aria-label='table'
              size='small'
            >
              <TableHead>
                <TableRow>
                  <StickyTableCell className={classes.head}>
                    <TableCell>Name</TableCell>
                    <TableCell align='right'>Total Hours</TableCell>
                    <TableCell align='right'>Commit Rate</TableCell>
                    <TableCell align='right'>Pending Timesheets</TableCell>
                  </StickyTableCell>

                  {data.data.data.headerWeek.map((week) => {
                    return (
                      <TableCell
                        key={week}
                        align='center'
                        sx={{ minWidth: 130 }}
                      >
                        Week {week}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data.data.users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TimeSheetbody
                      key={row._id}
                      row={row}
                      headerWeek={data.data.data.headerWeek}
                    />
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 100]}
              component='div'
              count={data.data.data.users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}
    </>
  );
}

export default TimeSheet;
