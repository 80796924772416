import React from 'react';
import {
  Stack,
  Card,
  Box,
  CircularProgress,
  Typography,
  Button,
} from '@mui/material';
import TicketDetails from './TicketDetails';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import exportFromJSON from 'export-from-json';

const AllTicket = ({ batchData }) => {
  const fetchTicket = async () => {
    const res = await axios.get(API_URL + '/ticket', {
      params: { batchId: batchData._id, limit: 0 },
    });
    return res;
  };

  const { status, data } = useQuery(`ticket${batchData._id}`, fetchTicket);

  let ticketData = [];

  if (data?.data?.data) {
    ticketData = data?.data?.data?.map((el) => {
      // const closedOn = el.activity.find((item) => {
      //   return item.status === 'closed';
      // })?.time;
      return {
        batch: batchData.name,
        userName: el.user?.name || el.user?.email,
        subject: el.subject,
        description: el.description,
        status: el.status,
        TicketCreatedAt: format(new Date(el.createdAt), 'do LLLL yyyy'),
        // TicketClosedon: closedOn
        //   ? format(new Date(closedOn), 'do LLLL yyyy')
        //   : '',
        department: el.department,
      };
    });
  }

  const exportData = () => {
    const fileName = `${batchData.name}-Ticket`;
    const exportType = 'csv';
    exportFromJSON({ data: ticketData, fileName, exportType });
  };

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <>
          <Stack
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={2}
          >
            <Button onClick={exportData}>DownLoad Tickets </Button>
          </Stack>
          <Stack spacing={1} sx={{ p: 1, pr: 0 }}>
            {data.data.data.map((ticket) => {
              return (
                <Stack
                  component={Card}
                  key={ticket._id}
                  sx={{
                    cursor: 'pointer',
                    p: 2,
                    bgcolor: (ticket.status === 'open' && '#FFFF99') || '',

                    '&:hover': {
                      background: '#F5F5F5',
                    },
                  }}
                >
                  <TicketDetails ticket={ticket} />
                </Stack>
              );
            })}
          </Stack>
        </>
      )}
    </div>
  );
};

export default AllTicket;
