import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from './App';

import axios from 'axios';

const queryClient = new QueryClient();

const Authorization = localStorage.getItem('authorization');

axios.defaults.headers.common['Authorization'] = Authorization;

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);
