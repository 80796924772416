import React from 'react';
import SuperCapstoneReporting from '../components/_dashboard/superCapstone';
import Page from '../components/Page';
import { Container, Grid } from '@mui/material';

const SuperCapstone = () => {
  return (
    <Page title='Dashboard | Capstone'>
      <Container maxWidth='xl'>
        <Grid item xs={12} sm={12} md={12}>
          <SuperCapstoneReporting />
        </Grid>
      </Container>
    </Page>
  );
};

export default SuperCapstone;
