import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

function AttendanceBody({ row, allEvents, showDetail }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    head: {
      backgroundColor: '#fff',
      minWidth: '100px',
    },
    tableContainer: {
      maxHeight: '400px',
    },
    cell: {
      minWidth: '120px',
      maxWidth: '130px',
    },
  }));

  const StickyTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#ddd',
      minWidth: '50px',
      left: 0,
      position: 'sticky',
      zIndex: theme.zIndex.appBar + 2,
    },
    body: {
      backgroundColor: '#ddd',
      minWidth: '50px',
      left: 0,
      position: 'sticky',
      align: 'center',
      zIndex: theme.zIndex.appBar + 1,
    },
  }))(TableCell);

  const classes = useStyles();
  return (
    <>
      <TableRow key={row._id}>
        <StickyTableCell>
          <TableCell
            component='th'
            scope='row'
            padding='none'
            align='left'
            className={classes.cell}
          >
            {row.name}
          </TableCell>
          {showDetail && (
            <>
              <TableCell align='center' className={classes.cell}>
                {row.eventAttent}
              </TableCell>
              <TableCell align='center' className={classes.cell}>
                {row.totalEventDone ? `${row.attendanceRate} %` : '-'}
              </TableCell>
              <TableCell align='center' className={classes.cell}>
                {row.coach}
              </TableCell>
              <TableCell align='center' className={classes.cell}>
                {row.company}
              </TableCell>
              <TableCell
                sx={{ wordBreak: 'break-word' }}
                align='center'
                className={classes.cell}
              >
                {row.email}
              </TableCell>
            </>
          )}
        </StickyTableCell>
        {allEvents.map((event) => {
          const myevent = row.events.filter((userEvents) => {
            return userEvents._id === event._id;
          })[0];
          return (
            <TableCell key={event._id} align='center'>
              {event.attendanceUploaded ? (
                myevent ? (
                  myevent.attendance && myevent.attendance.status ? (
                    <Typography>P</Typography>
                  ) : (
                    <Typography color='#FF0000'>A</Typography>
                  )
                ) : (
                  <Typography color='#C8C8C8'>NA</Typography>
                )
              ) : (
                '-'
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
}

export default AttendanceBody;
