// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.warning.dark, 0.24)} 100%)`,
}));

export default function DataCard(props) {
  const TOTAL = props.total;
  const title = props.title;
  const Icon = props.icon;
  const bgColor = props.bgColor;
  const textColor = props.textColor;

  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    color: textColor,
    backgroundColor: bgColor,
  }));

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon />
      </IconWrapperStyle>
      <Typography variant='h3'>{TOTAL}</Typography>
      <Typography variant='subtitle1' sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </RootStyle>
  );
}
