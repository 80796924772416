import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';

import SubEvent from './SubEvent';

export default function EventSchedule({ batchData }) {
  const fetchEventSchedule = async () => {
    const res = await axios.get(API_URL + '/masterevent/all-events', {
      params: { batchId: batchData._id },
    });
    return res;
  };

  const { status, data } = useQuery(
    `EventSchedule${batchData._id}`,
    fetchEventSchedule
  );

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <TableContainer sx={{ maxHeight: 450 }}>
          <Table
            sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
            stickyHeader
            aria-label='sticky table'
            size='small'
          >
            <TableHead>
              <TableRow>
                <TableCell align='center' style={{ width: '3%' }}></TableCell>
                <TableCell align='center' style={{ width: '3%' }}></TableCell>
                <TableCell align='left'>Event</TableCell>
                <TableCell align='center'>Scheduled On</TableCell>
                <TableCell align='center'>Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data.data.map((event, index) => (
                <SubEvent key={index} event={event} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
