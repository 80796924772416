import React, { useState } from 'react';
import { formatDistance } from 'date-fns';
import { Box, Stack, Typography, Grid, Container, Link } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { UPLOAD_OR_DOWNLOAD_URL } from '../../../../links/API_LINKS';
import htmlparser from 'html-react-parser';

const TicketDetails = ({ ticket }) => {
  const [showDetails, setShowDetails] = useState(false);

  const { subject, description, user } = ticket;
  const createdAt = formatDistance(new Date(ticket.createdAt), new Date(), {
    addSuffix: true,
  });

  const comment = ticket.activity.filter((act) => {
    return act.comment;
  });

  return (
    <div>
      {showDetails ? (
        <Container>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
          >
            <Typography variant='h6'>{ticket.subject}</Typography>

            <Typography
              variant='caption'
              sx={{ pr: 1, flexShrink: 0, color: 'text.secondary' }}
              onClick={() => {
                setShowDetails(false);
              }}
            >
              <KeyboardArrowUpIcon />
            </Typography>
          </Stack>
          <Typography sx={{ p: 2 }}>{ticket.description}</Typography>
          <Grid sx={{ p: 2 }} container spacing={4}>
            {ticket.attachments.map((image, index) => {
              return (
                <Grid key={index} item xs={6} md={4}>
                  <Link
                    target='_blank'
                    href={UPLOAD_OR_DOWNLOAD_URL + '/' + image}
                  >
                    <img
                      src={UPLOAD_OR_DOWNLOAD_URL + '/' + image}
                      alt='attachment'
                      loading='lazy'
                    />
                  </Link>
                </Grid>
              );
            })}
          </Grid>
          {comment.map((myComment, index) => {
            return (
              <Stack
                key={index}
                sx={{ p: 2 }}
                direction='row'
                alignItems='center'
                spacing={4}
              >
                <Typography variant='subtitle2'>{myComment.owner}</Typography>

                <Typography>{htmlparser(myComment.comment)}</Typography>
              </Stack>
            );
          })}
        </Container>
      ) : (
        <>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            spacing={2}
          >
            <Box sx={{ minWidth: 240, flexGrow: 1 }}>
              <Typography variant='subtitle2' noWrap>
                {subject}
              </Typography>

              <Typography
                variant='body2'
                sx={{ color: 'text.secondary' }}
                noWrap
              >
                {description}
              </Typography>
            </Box>
            <Typography
              variant='caption'
              sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}
            >
              {createdAt}
            </Typography>

            <Typography
              variant='caption'
              sx={{ pr: 1, flexShrink: 0, color: 'text.secondary' }}
            >
              {ticket.status}
            </Typography>
            <Typography
              variant='caption'
              sx={{ pr: 1, flexShrink: 0, color: 'text.secondary' }}
              onClick={() => {
                setShowDetails(true);
              }}
            >
              <KeyboardArrowDownIcon />
            </Typography>
          </Stack>
          <Typography variant='caption'>
            Asked by {user.name || user.email}
          </Typography>
        </>
      )}
    </div>
  );
};

export default TicketDetails;
