import React from 'react';
import { Box, Container } from '@mui/material';
import { Button, CircularProgress, Grid } from '@mui/material';

import useUserData from '../../utils/useUserData';
import Details from './profile/elements/Details';
import WorkExperience from './profile/elements/WorkExperience';
import ProjectDetails from './profile/elements/Projects';
import Education from './profile/elements/Education';
import BelongScoreReport from './profile/belongscorereport';

const NewProfile = ({ setShowProfile, userId }) => {
  const { status, data: user } = useUserData(userId);
  const content = status === 'success' && (
    <Container>
      <Button
        variant='outlined'
        onClick={() => setShowProfile(null)}
        sx={{ marginBottom: 4 }}
      >
        Back
      </Button>
      <Grid container rowSpacing={4} columnSpacing={4}>
        <Grid item xs={12} sm={12}>
          <Details user={user} />
        </Grid>
        {user.education?.length ? (
          <Grid item xs={12} sm={6}>
            <Education education={user.education} />
          </Grid>
        ) : null}
        {user.workExperience?.length ? (
          <Grid item xs={12} sm={6}>
            <WorkExperience workExperience={user.workExperience} />
          </Grid>
        ) : null}
        {user.projectPortfolio?.length ? (
          <Grid item xs={12} sm={6}>
            <ProjectDetails projectPortfolio={user.projectPortfolio} />
          </Grid>
        ) : null}
      </Grid>
      <Box>
        <BelongScoreReport userId={userId} areas={user.areas} />
      </Box>
    </Container>
  );
  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {content}
    </>
  );
};

export default NewProfile;
