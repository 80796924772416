import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';

const SuperCapstoneTable = ({ data }) => {
  // const asd = data.map((el) => {
  //   const myObj = {
  //     Email: el.userId.email,
  //     Name: el.userId.firstName,
  //     Capstone: el.superCapstoneId.title,
  //   };
  //   if (el.feedback?.data) {
  //     el.feedback?.data.forEach((elm) => {
  //       myObj[elm.question] = elm.response;
  //     });
  //   }
  //   return myObj;
  // });
  // console.log(asd);
  return (
    <TableContainer component={Card}>
      <Table
        sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
        aria-label='table'
      >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='left'>Email</TableCell>
            <TableCell align='left'>Capstone</TableCell>
            <TableCell align='left'>Tasks Done</TableCell>
            <TableCell align='left'>Quiz Done</TableCell>
            <TableCell align='left'>Quiz Avg %</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => {
            const quizDone = item.quiz.filter((el) => {
              return el.percentage;
            });
            const avgPercentage = quizDone.length
              ? quizDone.reduce((sum, elm) => sum + elm.percentage, 0) /
                quizDone.length
              : '-';
            return (
              <TableRow
                key={item._id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component='th' scope='row'>
                  {item.userId.firstName}
                </TableCell>
                <TableCell align='left'>{item.userId.email}</TableCell>
                <TableCell align='left'>
                  {item.superCapstoneId.title}
                  <Typography>
                    {' '}
                    {item.superCapstoneId?.companyId?.name}{' '}
                  </Typography>
                </TableCell>
                <TableCell align='left'>{item.tasks?.length}</TableCell>
                <TableCell align='left'>{quizDone.length}</TableCell>
                <TableCell align='left'>{avgPercentage}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SuperCapstoneTable;
