import React from 'react';
import { useReactToPrint } from 'react-to-print';
// import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { format } from 'date-fns';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import { withStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import '../../../../../src/custom-font.css';

const belongLogo =
  'https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png';

const VgaCertificate = ({ batchData, userDetails }) => {
  const completionStatus = userDetails.completionStatus;
  const certificateText = 'Certificate of ' + completionStatus;
  const detailsText = `in recognition of his/her outstanding performance and commitment during Virtual Global Apprenticeship in ${
    userDetails.area.title
  }. This achievement stands as evidence of his/her valuable input to the ${
    userDetails.company.name
  } project, for the duration ${format(
    new Date(batchData.startDate),
    'do LLLL y'
  )} to ${format(new Date(batchData.endDate), 'do LLLL y')}.`;

  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const topRightLogo =
    batchData.type === 'University' ? belongLogo : batchData.logoUrl;
  const companyLogo = userDetails.company.logoUrl;
  const mentorSign =
    userDetails.coach.coachSign || userDetails.mentor.mentorSign;

  const certificateWidth = 900;
  const certificateHeight = 800;

  const printStyles = `
  @page {
    size: ${certificateWidth}px ${certificateHeight}px;
    margin: 0;
  }

  @media print {
    body {
      margin: 0;
    }

    .certificate-container {
      width: ${certificateWidth}px;
      height: ${certificateHeight}px;
      padding: 0;
      margin: 0;
      page-break-after: always;
    }
  }
`;

  return (
    <>
      {completionStatus === 'Not Applicable' ? (
        <div style={{ margin: '1.5rem' }}>
          <Typography
            variant='h4'
            fontWeight='bold'
            color='primary.DEFAULT'
            gutterBottom
          >
            Thank you for submitting your feedback.
          </Typography>
          <div style={{ textAlign: 'center' }}>
            Please submit all the company project tasks and assignments to
            access your VGA Certificate.
          </div>
        </div>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 2,
              borderWidth: 2,
              borderBottomWidth: 0,
              backgroundColor: 'bg-gray-50',
            }}
          >
            <Button
              sx={{
                mx: 'auto',
                borderRadius: 'rounded',
                marginTop: 3,
                color: 'text-gray-100',
                fontSize: 'text-sm',
                paddingX: 8,
                paddingY: 2,
                backgroundColor: 'bg-primary',
                '&:hover': {
                  backgroundColor: 'bg-primary-light',
                },
                fontWeight: 'font-bold',
              }}
              onClick={handlePrint}
            >
              Download Certificate
            </Button>
          </Box>
          {/* thick border */}
          <style>{printStyles}</style>
          <Box
            ref={componentRef}
            // style={{ minWidth: '900px', maxWidth: '900px' }}
            sx={{
              width: 900,
              mx: 'auto',
              margin: 2,
              padding: 1,
              border: 6,
              borderColor: 'primary.main',
              backgroundColor: 'bg-gray-50',
            }}
          >
            <Box
              sx={{
                mx: 'auto',
                border: 3,
                borderColor: 'primary.main',
                backgroundColor: 'bg-gray-50',
              }}
            >
              <Box
                style={{ background: 'white' }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mx: 'auto',
                }}
              >
                <Box
                  sx={{
                    borderWidth: 4,
                    borderColor: 'primary.main',
                    backgroundColor: 'white',
                    margin: 3,
                  }}
                >
                  <Box>
                    <div sx={{ margin: 2 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginBottom: 4,
                        }}
                      >
                        <Box display='flex'>
                          <img
                            src={belongLogo}
                            alt='Logo'
                            style={{ height: '48px' }}
                          />
                          <img
                            src={companyLogo}
                            alt='Logo'
                            style={{ height: '48px', marginLeft: '8px' }}
                          />
                        </Box>

                        <img
                          src={topRightLogo}
                          alt='Logo'
                          style={{ height: '48px' }}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          variant='h1'
                          gutterBottom
                          sx={{
                            textAlign: 'center',
                          }}
                          className='dancing-script'
                        >
                          {certificateText}
                        </Typography>
                        <span style={{ margin: '0 2rem' }}>{''}</span>
                        <Typography
                          variant='subtitle1'
                          gutterBottom
                          style={{
                            textAlign: 'center',
                            fontFamily: 'lora',
                            fontWeight: 'bold',
                            marginTop: '24px',
                          }}
                        >
                          This is to certify that
                        </Typography>
                        <Typography
                          variant='h5'
                          gutterBottom
                          style={{
                            textAlign: 'center',
                            fontFamily: 'lora',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            marginTop: '24px',
                          }}
                        >
                          {userDetails.name.toUpperCase()}
                        </Typography>

                        <Typography
                          variant='subtitle1'
                          gutterBottom
                          style={{
                            textAlign: 'center',
                            fontFamily: 'lora',
                            fontWeight: 'bold',
                            marginTop: '24px',
                          }}
                        >
                          {detailsText}
                        </Typography>
                      </Box>
                      {/* Both signature fields */}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: '32px',
                          fontFamily: 'Lora, serif', // Equivalent to 'font-lora'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={mentorSign}
                            alt='Logo'
                            style={{
                              height: '48px',
                              borderBottom: '2px solid black',
                            }}
                          />

                          <Typography
                            variant='subtitle2'
                            style={{
                              textAlign: 'center',
                              fontFamily: 'lora',
                              fontWeight: 'bold',
                              marginTop: '24px',
                            }}
                          >
                            Industry Mentor
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              borderBottom: '2px solid black',
                            }}
                          >
                            <img
                              src='https://res.cloudinary.com/belong/image/upload/v1643353663/uploaded_resources/One_no4yyb.gif'
                              alt='Logo'
                              style={{ height: '48px' }}
                            />
                            <img
                              src='https://res.cloudinary.com/belong/image/upload/v1643353575/uploaded_resources/Two_yy5ee6.gif'
                              alt='Logo'
                              style={{ height: '48px' }}
                            />
                          </Box>
                          <Typography
                            variant='subtitle2'
                            style={{
                              textAlign: 'center',
                              fontFamily: 'lora',
                              fontWeight: 'bold',
                              marginTop: '24px',
                            }}
                          >
                            Belong Founders
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default VgaCertificate;
