import React from 'react';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CapstoneDetailTable from './CapstoneDetailTable';

const Index = ({ currentCapstone, setCurrentCapstone, setShowProfile }) => {
  return (
    <>
      <Button
        size='large'
        variant='text'
        style={{ color: 'black' }}
        onClick={() => setCurrentCapstone(null)}
      >
        <ArrowBackIcon />
      </Button>
      <CapstoneDetailTable
        currentCapstone={currentCapstone}
        setShowProfile={setShowProfile}
      />
    </>
  );
};

export default Index;
