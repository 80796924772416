import React from 'react';
import { useTheme } from '@mui/styles';
import { Paper, Typography } from '@mui/material';
// import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import WorkIcon from '@mui/icons-material/Work';
import { Box } from '@mui/system';
import { format } from 'date-fns';

const WorkExperience = ({ workExperience }) => {
  const theme = useTheme();
  const work = workExperience.sort(function (a, b) {
    return new Date(b.startDate) - new Date(a.startDate);
  });

  const content = work.map((work, index) => {
    return (
      <Box mb={4} key={index}>
        <Box display='flex' justifyContent='space-between' mb={1}>
          <Box>
            <Typography variant='subtitle1'>{work.title}</Typography>
            <Typography>{work.companyName}</Typography>
          </Box>
          <Box>
            <Typography variant='subtitle2' color={theme.palette.grey[500]}>
              {format(new Date(work.startDate), 'do MMM yyy')} -{' '}
              {work.currentlyWorkingHere
                ? 'Till Now'
                : work.endDate && format(new Date(work.endDate), 'do MMM yyy')}
            </Typography>
          </Box>
        </Box>
        <Typography variant='body2' color={theme.palette.grey[600]}>
          {work.description}
        </Typography>
      </Box>
    );
  });
  return (
    <Paper sx={{ padding: theme.spacing(2), height: '100%' }} elevation={2}>
      <Box
        display='flex'
        alignItems='center'
        marginTop={theme.spacing(1)}
        gap={theme.spacing(1)}
      >
        <WorkIcon />
        <Typography variant='h5'>Work History</Typography>
      </Box>
      <Box mt={4}>{content}</Box>
    </Paper>
  );
};

export default WorkExperience;
