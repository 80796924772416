import { combineReducers } from 'redux';

import authReducer from './auth/authReducer';
import partnerReducer from './partner/partnerReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  partner: partnerReducer,
});

export default rootReducer;
