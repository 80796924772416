import React, { useState, useRef } from 'react';
import TableCell from '@mui/material/TableCell';
import { Button, TableRow } from '@mui/material';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PDFExport } from '@progress/kendo-react-pdf';
import DeviareCertificate from './DeviareCertificate';
import DeviareInternshipCertificate from './DeviareInternshipCertificate';

function DeviareLeaderboardbody({
  row,
  isTimeSheet,
  isBatchCompleted,
  batchData,
}) {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const pdfExportComponent = useRef(null);
  const [isPdfReady, setIsPdfReady] = useState(false);

  const useStyles = makeStyles((theme) => ({
    nameColor: {
      color: open ? 'blue' : '',
    },
  }));

  const classes = useStyles();

  const handleExportPDF = (rowData) => {
    const matchedUser = batchData.users.find(
      (user) => user.email === rowData.email
    );
    if (matchedUser) {
      setSelectedUser([matchedUser]);
      setIsPdfReady(true);
      
      // Use requestAnimationFrame to ensure the PDF content is rendered
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
          }
          // Reset isPdfReady after a short delay
          setTimeout(() => setIsPdfReady(false), 100);
        });
      });
    } else {
      console.error('User not found');
      // Optionally, you can show an error message to the user here
    }
  };
  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component='th' scope='row' padding='none'>
          <Typography
            variant='subtitle2'
            className={classes.nameColor}
            onClick={() => setOpen(!open)}
            sx={{ cursor: 'pointer' }}
            noWrap
          >
            {row.name}
          </Typography>
        </TableCell>
        <TableCell
          align='center'
          sx={{
            color: row.employabilityScore < 72.5 ? 'error.main' : 'inherit',
          }}
        >
          {row.employabilityScore}
        </TableCell>
        <TableCell
          align='center'
          sx={{ color: row.attendanceRate < 80 ? 'error.main' : 'inherit' }}
        >
          {row.attendanceRate.toFixed(2)} %{' '}
        </TableCell>
        <TableCell
          align='center'
          sx={{ color: row.submissionRate < 70 ? 'error.main' : 'inherit' }}
        >
          {row.submissionRate} %
        </TableCell>

        <TableCell
          align='center'
          sx={{ color: row.projectTaskScore < 70 ? 'error.main' : 'inherit' }}
        >
          {' '}
          {row.projectTaskScore} %{' '}
        </TableCell>
        <TableCell
          align='center'
          sx={{ color: row.pitchPresentation < 70 ? 'error.main' : 'inherit' }}
        >
          {' '}
          {row.pitchPresentation} %{' '}
        </TableCell>
        <TableCell
          align='center'
          sx={{ color: row.vgaQuiz < 70 ? 'error.main' : 'inherit' }}
        >
          {' '}
          {row.vgaQuiz}%{' '}
        </TableCell>
        <TableCell
          align='center'
          sx={{ color: row.aptitudeQuiz < 70 ? 'error.main' : 'inherit' }}
        >
          {' '}
          {row.aptitudeQuiz} %{' '}
        </TableCell>
        <TableCell align='center'>
          {isBatchCompleted &&
            (row.completionStatus === 'Not Applicable'
              ? '-'
              : row.completionStatus)}
        </TableCell>
        <TableCell align='center'>
          <Button
            variant='contained'
            disabled={
              row.completionStatus === '-' || row.completionStatus === undefined
            }
            onClick={() => handleExportPDF(row)}
          >
            Download
          </Button>
        </TableCell>

        {isTimeSheet && (
          <TableCell
            align='center'
            sx={{
              color: row.timeCommitmentRate < 70 ? 'error.main' : 'inherit',
            }}
          >
            {' '}
            {row.timeCommitmentRate.toFixed(2)} %{' '}
          </TableCell>
        )}
      </TableRow>
      <div style={{ position: 'absolute', left: '-9999px', top: 0 }}>
        {isPdfReady && (
          <PDFExport paperSize='auto' landscape={true} ref={pdfExportComponent}>
            {selectedUser &&
              (isTimeSheet ? (
                <DeviareInternshipCertificate
                  batchData={{ ...row, users: selectedUser }}
                  isAllTaskDone={true}
                  batchDetails={batchData}
                />
              ) : (
                <DeviareCertificate
                  batchData={{ ...row, users: selectedUser }}
                  isAllTaskDone={true}
                  batchDetails={batchData}
                />
              ))}
          </PDFExport>
        )}
      </div>
    </>
  );
}

export default DeviareLeaderboardbody;
