import React from 'react';
import { IconButton, Link, Paper, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PublicIcon from '@mui/icons-material/Public';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import PhoneIcon from '@mui/icons-material/Phone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useTheme } from '@emotion/react';
import { UPLOAD_OR_DOWNLOAD_URL } from '../../../../links/API_LINKS';
const Details = ({ user }) => {
  const theme = useTheme();
  return (
    <Paper sx={{ padding: theme.spacing(2), height: '100%' }} elevation={2}>
      <Stack direction='row' spacing={2} justifyContent='space-around'>
        <Avatar
          alt={user.firstName}
          src={UPLOAD_OR_DOWNLOAD_URL + '/' + user.imageUrl}
          sx={{
            width: 100,
            height: 100,
            bgcolor: theme.palette.primary.light,
            alignSelf: 'center',
          }}
        />

        <Box>
          <Typography variant='h4'>
            {user.firstName} {user.lastName}
          </Typography>
          <Box
            display='flex'
            alignItems='center'
            marginTop={theme.spacing(2)}
            gap={theme.spacing(1)}
            color={theme.palette.grey[600]}
          >
            <Tooltip title='Email'>
              <MailOutlineIcon />
            </Tooltip>
            <Typography variant='body1'>{user.email}</Typography>
          </Box>
          {user.phone && (
            <Box
              display='flex'
              alignItems='center'
              marginTop={theme.spacing(1)}
              gap={theme.spacing(1)}
              color={theme.palette.grey[600]}
            >
              <Tooltip title='Phone'>
                <PhoneIcon />
              </Tooltip>
              <Typography variant='body1'>{user.phone}</Typography>
            </Box>
          )}
          {user.city && (
            <Box
              display='flex'
              alignItems='center'
              marginTop={theme.spacing(1)}
              gap={theme.spacing(1)}
              color={theme.palette.grey[600]}
            >
              <Tooltip title='Location'>
                <PublicIcon />
              </Tooltip>
              <Typography variant='body1'>
                {`${user.city}${user.country && ', ' + user.country}`}
              </Typography>
            </Box>
          )}
          {user.industryId && (
            <Box
              display='flex'
              alignItems='center'
              marginTop={theme.spacing(1)}
              gap={theme.spacing(1)}
              color={theme.palette.grey[600]}
            >
              <Tooltip title='Industry'>
                <EmojiTransportationIcon />
              </Tooltip>
              <Typography variant='body1'>{user.industryId.title}</Typography>
            </Box>
          )}
          {user.linkedInUrl && (
            <Link
              href={user.linkedInUrl}
              underline='none'
              target='_blank'
              rel='noopener'
            >
              <IconButton
                color='primary'
                aria-label='LinkedIn'
                sx={{ padding: '0px', marginTop: '8px' }}
              >
                <LinkedInIcon />
              </IconButton>
            </Link>
          )}

          {user.resumeUrl && (
            <Link
              href={`${UPLOAD_OR_DOWNLOAD_URL}/${user.resumeUrl}`}
              underline='none'
              target='_blank'
              rel='noopener'
            >
              <IconButton
                color='primary'
                aria-label='Resume'
                sx={{ padding: '0px', marginTop: '8px' }}
              >
                <AssignmentIndIcon />
              </IconButton>
            </Link>
          )}
        </Box>
      </Stack>

      {user.about && (
        <Box mt={4}>
          <Typography color={theme.palette.grey[700]}>{user.about}</Typography>
        </Box>
      )}
    </Paper>
  );
};

export default Details;
