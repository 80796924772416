import React, { useState } from 'react';
import { filter } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import {
  TablePagination,
  Stack,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { ExportJsonCsv } from 'react-export-json-csv';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import TableListHead from '../../../layouts/batch/TableListHead';
// import NewLeaderboardBody from './NewLeaderboardBody';
import DeviareLeaderboardbody from './DeviareLeaderboardbody';
// import { ExportJsonCsv } from 'react-export-json-csv';

function DeviareLeaderboardHeader({ data, batchData }) {
  const isTimeSheet = batchData.timeSheet && batchData.timeSheet.startsAt;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('employabilityScore');
  const [userStatus, setUserStatus] = useState('All');

  const allUsers = batchData.users || [];

  const isBatchCompleted = batchData.status === 'Completed';

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const totalCount = data.length;
  const activeCount = data.filter((user) => {
    return user.status === 'Active';
  }).length;
  const inactiveCount = data.filter((user) => {
    return user.status === 'Inactive';
  }).length;
  const adminCount = data.filter((user) => {
    return user.status === 'Admin';
  }).length;

  const activeUser = data.filter((user) => {
    if (userStatus === 'All') {
      return true;
    }
    return userStatus === user.status;
  });

  let rows = activeUser.map((el) => {
    const userInfo = allUsers.find((item) => {
      return item.email === el.email;
    });
    const attendanceRate = el.vgaData?.attendanceRate || 0;
    const submissionRate = el.vgaData?.submissionRate || 0;
    const projectTaskScore = el.vgaData?.projectTaskScore || 0;
    const pitchPresentation = el.vgaData?.pitchPresentation || 0;
    const vgaQuiz = el.vgaData?.quizScore || 0;
    const aptitudeQuiz = el.aptitudeScore || 0;
    const completionStatus = isBatchCompleted
      ? el.completionStatus === 'Not Applicable'
        ? submissionRate >= 100
          ? 'Completion'
          : '-'
        : el.completionStatus
      : '-';
    const employabilityScore = el.employabilityScore;

    const finalObj = {
      name: el.name,
      email: el.email,
      attendanceRate,
      submissionRate,
      projectTaskScore,
      pitchPresentation,
      employabilityScore,
      completionStatus,
      vgaQuiz,
      aptitudeQuiz,
      domain: userInfo.area?.title,
    };

    if (isTimeSheet) {
      const timeCommitmentRate = el.timeCommitmentRate || 0;
      finalObj.timeCommitmentRate = timeCommitmentRate;
    }

    return finalObj;
  });

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    {
      id: 'employabilityScore',
      label: 'Employability Score',
      alignRight: false,
    },
    { id: 'attendanceRate', label: 'Attendance Rate', alignRight: false },
    { id: 'submissionRate', label: 'Submission Rate', alignRight: false },
    { id: 'projectTaskScore', label: 'Project Score', alignRight: false },
    { id: 'pitchPresentation', label: 'Pitch Presentation', alignRight: false },
    { id: 'vgaQuiz', label: 'VGA Quiz', alignRight: false },
    { id: 'aptitudeQuiz', label: 'Aptitude Quiz', alignRight: false },
    { id: 'completionStatus', label: 'Certificate Status', alignRight: false },
    {
      id: 'certificateDownload',
      label: 'Download Certificate',
      alignRight: false,
    },
  ];

  if (isTimeSheet) {
    TABLE_HEAD.push({
      id: 'timeCommitmentRate',
      label: 'Time Commitment Rate',
      alignRight: false,
    });
  }

  const headers = [
    { key: 'name', name: 'Name' },
    { key: 'email', name: 'Email' },
    { key: 'domain', name: 'Domain' },
    { key: 'employabilityScore', name: 'Employability Score' },
    { key: 'attendanceRate', name: 'Attendance Rate' },
    { key: 'submissionRate', name: 'Submission Rate' },
    { key: 'projectTaskScore', name: 'Project Score' },
    { key: 'pitchPresentation', name: 'Pitch Presentation' },
    { key: 'vgaQuiz', name: 'VGA Quiz' },
    { key: 'aptitudeQuiz', name: 'Aptitude Quiz' },
  ];

  if (isTimeSheet) {
    headers.push({ key: 'timeCommitmentRate', name: 'Time Commitment Rate' });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={1}
      >
        <FormControl sx={{ marginInline: 4, width: 200 }} size='small'>
          <InputLabel id='demo-simple-select-label'>User Status</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={userStatus}
            label='userStatus'
            onChange={(e) => setUserStatus(e.target.value)}
          >
            <MenuItem value='All'>All ({totalCount}) </MenuItem>
            <MenuItem value='Active'>Active ({activeCount}) </MenuItem>
            <MenuItem value='Inactive'>Inactive ({inactiveCount}) </MenuItem>
            <MenuItem value='Admin'>Admin ({adminCount})</MenuItem>
          </Select>
        </FormControl>
        <Stack
          direction='row'
          justifyContent='justify-end'
          alignItems='center'
          spacing={1}
        >
          <Tooltip
            title={
              <>
                <Typography>Merit Certificate Criteria</Typography>
                <Typography>Submission Rate: 100% </Typography>
                <Typography>Attendance Rate: 75% </Typography>
                <Typography>Pitch Presentation*: 70% </Typography>
                <Typography>Aptitude Quiz: 70% </Typography>
                <Typography>VGA Quiz Score: 70% </Typography>
                <Typography>Project Score: 70% </Typography>
              </>
            }
          >
            <InfoIcon />
          </Tooltip>

          <ExportJsonCsv headers={headers} items={filteredUsers}>
            <Button variant='outlined'>Leaderboard Details</Button>
          </ExportJsonCsv>
        </Stack>
      </Stack>
      <TableContainer sx={{ maxHeight: 500 }} component={Paper}>
        <Table
          stickyHeader
          sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
          aria-label='table'
          size='small'
        >
          <TableListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={rows.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <DeviareLeaderboardbody
                  key={index}
                  row={row}
                  batchData={batchData}
                  isTimeSheet={isTimeSheet}
                  isBatchCompleted={isBatchCompleted}
                />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

export default DeviareLeaderboardHeader;
