import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { Button, TableRow } from '@mui/material';
import { Stack, Typography, Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import HvCertificate from './HvCertifacte';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1100,
  padding: 4,
  height: '80%',
  overflow: 'scroll',
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '10px',
};

const CertifacteBody = ({ row, batchData }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow key={row.email}>
        <TableCell component='th' scope='row' padding='none'>
          <Stack direction='row' alignItems='left' spacing={2}>
            <Typography variant='subtitle2' noWrap>
              {row.name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align='left'>{row.email}</TableCell>

        <TableCell align='center'>
          {row.completionStatus === 'Not Applicable' ? (
            '-'
          ) : (
            <Button onClick={() => setOpen(true)}>
              {' '}
              {row.completionStatus}{' '}
            </Button>
          )}
        </TableCell>
      </TableRow>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <HvCertificate
            isAllTaskDone={true}
            batchData={batchData}
            userDetails={row.userDetails}
          />
        </Box>
      </Modal>
    </>
  );
};

export default CertifacteBody;
