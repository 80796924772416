import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow } from '@mui/material';

function DataBody({ row }) {
  return (
    <>
      <TableRow
        key={row.email}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component='th' scope='row' padding='none'>
          {row.name}
        </TableCell>
        <TableCell align='center'> {row.email} </TableCell>
        <TableCell align='center'>
          {row.domainPercentage ? `${row.domainPercentage} %` : '-'}
        </TableCell>
        <TableCell align='center'>
          {' '}
          {row.aptitudePercentage ? `${row.aptitudePercentage} %` : '-'}
        </TableCell>
        <TableCell align='center'>
          {row.profileStrength ? `${row.profileStrength} /10` : '-'}
        </TableCell>
        <TableCell align='center'>{row.belongScore}</TableCell>
      </TableRow>
    </>
  );
}

export default DataBody;
