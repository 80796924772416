import axios from 'axios';
import { useQuery } from 'react-query';
import { API_URL } from '../links/API_LINKS';

const fetchUserDetails = (arg) => {
  const { queryKey } = arg;
  const userId = queryKey[1];
  return axios.get(API_URL + '/user', {
    params: {
      userId: userId,
    },
  });
};

const useUserData = (userId) => {
  return useQuery(['fetchUserDetails', userId], fetchUserDetails, {
    select: (data) => {
      const transformedData = data.data.data;
      return transformedData;
    },
  });
};

export default useUserData;
