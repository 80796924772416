import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ClassIcon from '@mui/icons-material/Class';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
// import WorkIcon from '@mui/icons-material/Work';
// import PublicIcon from '@mui/icons-material/Public';
import AssignmentIcon from '@mui/icons-material/Assignment';

// components
import Page from '../components/Page';
import DataCard from '../components/_dashboard/app/DataCard';
//import Admins from '../components/_dashboard/app/Admins';

import { useQuery } from 'react-query';
import axios from 'axios';

import { API_URL } from '../links/API_LINKS';

const infoLighter = '#D0F2FF';
const infoDarker = '#04297A';
const warningLighter = '#FFF7CD';
const warningDarker = '#7A4F01';
const primaryLighter = '#C8FACD';
const primaryDarker = '#005249';
const errorLighter = '#FFE7D9';
const errorDarker = '#7A0C2E';

const DashboardApp = () => {
  const fetchDashboard = async () => {
    const res = await axios.get(API_URL + '/dashboard/partner');
    return res;
  };

  const { status, data } = useQuery('fetchDashboard', fetchDashboard);
  return (
    <Page title='Dashboard | Belong'>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <Container maxWidth='xl'>
          <Box sx={{ pb: 5 }}>
            <Typography variant='h4'>Overview</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <DataCard
                total={data.data.data.batchesCount}
                title='Batches'
                icon={ClassIcon}
                bgColor={infoLighter}
                textColor={infoDarker}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <DataCard
                total={data.data.data.eventsCount}
                title='Events'
                icon={EmojiEventsIcon}
                bgColor={warningLighter}
                textColor={warningDarker}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <DataCard
                total={data.data.data.tasksCount}
                title='Assignments'
                icon={AssignmentIcon}
                bgColor={primaryLighter}
                textColor={primaryDarker}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <DataCard
                total={data.data.data.usersCount}
                title='Total Users'
                icon={PeopleIcon}
                bgColor={warningLighter}
                textColor={warningDarker}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <DataCard
                total={data.data.data.companiesCount}
                title='Companies'
                icon={BusinessIcon}
                bgColor={errorLighter}
                textColor={errorDarker}
              />
            </Grid>

            {/* <Grid item xs={12} sm={12} md={9} sx={{ marginTop: '30px' }}>
              <Admins />
            </Grid> */}
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default DashboardApp;
