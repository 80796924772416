import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
// components
import Logo from '../../Logo';
import Scrollbar from '../../misc/Scrollbar';
import NavSection from '../../misc/NavSection';
import { MHidden } from '../../@material-extend';
import SidebarConfig from './SidebarConfig';
//

import { connect } from 'react-redux';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

const DashboardSidebar = ({ isOpenSidebar, onCloseSidebar, partner }) => {
  const { pathname } = useLocation();
  const partnerData = partner.partner.data;

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to='/' sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline='none' component={RouterLink} to='#'>
          <AccountStyle>
            {/* <Avatar
              src='https://res.cloudinary.com/belong/image/upload/v1630905022/uploaded_resources/1614257285167_ei5dt4.jpg'
              alt='photoURL'
            /> */}
            <Box sx={{ ml: 2 }}>
              <Typography variant='subtitle2' sx={{ color: 'text.primary' }}>
                {partnerData.name}
              </Typography>
              {/* <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                Customer Success Manager
              </Typography> */}
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={SidebarConfig} />

      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          sx={{ padding: '16px 16px', mt: 5, backgroundSize: 'cover' }}
          component='img'
          image={partnerData.partnerId.logoUrl}
          alt='image'
        />
      </Card>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width='lgUp'>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width='lgDown'>
        <Drawer
          open
          variant='persistent'
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
};

const mapStateToProps = (state) => {
  return {
    partner: state.partner,
  };
};

export default connect(mapStateToProps)(DashboardSidebar);
