import axios from 'axios';
import { useQuery } from 'react-query';
import { API_URL } from '../links/API_LINKS';

const fetchUserBelongScore = (arg) => {
  const { queryKey } = arg;
  const userId = queryKey[1];
  const areaId = queryKey[2];
  let paramsObj = { userId };
  if (areaId) {
    paramsObj.areaId = areaId;
  }
  return axios.get(API_URL + '/user/profile/score-summary', {
    params: paramsObj,
  });
};

const useScoreSummaryData = (userId, areaId) => {
  return useQuery(
    ['fetchUserBelongScore', userId, areaId],
    fetchUserBelongScore,
    {
      select: (data) => {
        const transformedData = data.data.data;
        return transformedData;
      },
    }
  );
};

export default useScoreSummaryData;
