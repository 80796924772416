import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, CircularProgress, Typography, Chip } from '@mui/material';
import { format } from 'date-fns';
import { useQuery } from 'react-query';
import axios from 'axios';
import AddCsm from './AddCsm';

import { API_URL } from '../../../links/API_LINKS';

const BatchSummaryTable = ({ setCurrentBatch }) => {
  const fetchBatch = async () => {
    const res = await axios.get(
      API_URL + '/batch/info'
      // , {
      //   params: { partnerId: partnerData.partnerId._id },
      // }
    );
    return res;
  };

  const { status, data } = useQuery('fetchBatch', fetchBatch);

  function currentWeek(startDate, week) {
    const dt1 = new Date(startDate);
    const dt2 = new Date();

    const totalDays = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
    const myWeek = Math.ceil((totalDays + 1) / 7);
    return myWeek > week || myWeek < 0 ? '_' : myWeek;
  }
  return (
    <>
      {' '}
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <TableContainer sx={{ maxHeight: 550 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
            aria-label='table'
          >
            <TableHead>
              <TableRow>
                <TableCell>Batch</TableCell>
                <TableCell align='right'>Users</TableCell>
                <TableCell align='right'>Start Date</TableCell>
                <TableCell align='right'>End Date</TableCell>
                <TableCell align='right'>Status</TableCell>
                <TableCell align='right'>Duration</TableCell>
                <TableCell align='right'>Current Week</TableCell>
                <TableCell align='center'>CSM</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data.data.map((batch) => (
                <TableRow
                  key={batch._id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell
                    onClick={() => {
                      setCurrentBatch(batch);
                    }}
                    component='th'
                    scope='row'
                    sx={{ cursor: 'pointer' }}
                  >
                    {batch.name}
                  </TableCell>
                  <TableCell align='right'>{batch.usersCount}</TableCell>
                  <TableCell align='right'>
                    {format(new Date(batch.startDate), 'do LLLL yyyy')}
                  </TableCell>
                  <TableCell align='right'>
                    {batch.endDate &&
                      format(new Date(batch.endDate), 'do LLLL yyyy')}
                  </TableCell>
                  <TableCell align='right'>
                    {batch.status === 'Active' ? (
                      <Chip color='primary' size='small' label='Active' />
                    ) : (
                      <Chip size='small' label={batch.status} />
                    )}
                  </TableCell>
                  <TableCell align='right'>{batch.week} Week</TableCell>
                  <TableCell align='center'>
                    {currentWeek(batch.startDate, batch.week)}
                  </TableCell>
                  <TableCell align='right'>
                    <AddCsm batch={batch} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default BatchSummaryTable;
