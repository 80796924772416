import React, { useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Grid, CircularProgress, Box, Typography } from '@mui/material';
import CalenderEvent from './CalenderEvent';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'white',
  boxShadow: 24,
  borderRadius: '10px',
};

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const locales = {
  'en-IN': require('date-fns/locale/en-IN'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const ScheduleCalendar = ({ batchData }) => {
  const [event, setEvent] = useState();
  const [open, setOpen] = useState(false);
  const fetchAllEvents = async () => {
    const res = await axios.get(API_URL + '/dashboard/partner/journey-events', {
      params: {
        batchId: batchData._id,
      },
    });
    return res;
  };

  const { status, data } = useQuery(`Calendar${batchData._id}`, fetchAllEvents);
  let events = [];
  if (status === 'success') {
    events = data.data.data[0]
      ? data.data.data[0].events
          .filter((myEvent) => {
            return myEvent && myEvent.status === 'publish';
          })
          .map((event) => {
            const date = new Date(event.dateTime);
            const endDate = new Date(date.setHours(date.getHours() + 1));
            return {
              id: event._id,
              title: event.title,
              start: new Date(event.dateTime),
              end: endDate,
              type: 'event',
              link: event.link,
            };
          })
      : [];
  }

  let tasks = [];
  if (status === 'success') {
    tasks = data.data.data[0]
      ? data.data.data[0].tasks
          .filter((mytask) => {
            return mytask && mytask.status === 'publish';
          })
          .map((task) => {
            const date = new Date(task.dueDateTime);
            const endDate = new Date(date.setHours(date.getHours() + 1));
            return {
              id: task._id,
              title: task.title,
              start: new Date(task.dueDateTime),
              end: endDate,
              type: 'task',
            };
          })
      : [];
  }

  let taskEvents = [...events, ...tasks];

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12}>
              <Calendar
                localizer={localizer}
                events={taskEvents}
                startAccessor='start'
                endAccessor='end'
                style={{
                  height: 500,
                  margin: '10px',
                  fontSize: 14,
                }}
                onSelectEvent={(e) => {
                  setEvent(e);
                  setOpen((prev) => !prev);
                }}
                views={['week', 'day', 'month']}
                defaultView='month'
                components={{
                  event: CalenderEvent,
                }}
                eventPropGetter={(event, start, end, isSelected) => {
                  let newStyle = {
                    borderRadius: 4,
                    border: 'none',
                    padding: '0px',
                  };
                  return {
                    className: '',
                    style: newStyle,
                  };
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {event && (
        <Modal
          open={open}
          onClose={() => setOpen((prev) => !prev)}
          BackdropComponent={Backdrop}
        >
          <Box sx={style}>
            <span
              style={{
                display: 'flex',
                justifyContent: 'end',
                borderRadius: '10px 10px 0 0',
                color: 'white',
                backgroundColor: '#10ADB9',
                padding: '4px 10px',
              }}
              onClick={() => setOpen((prev) => !prev)}
            >
              <CloseIcon />
            </span>
            <p
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                height: '100px',
                borderBottom: '1px solid #10ADB9',
                backgroundColor: '#10ADB9',
                padding: '0 16px',
                color: 'white',
                display: 'flex',
                alignItems: 'end',
              }}
            >
              {event.title}
            </p>
            <div
              style={{
                textAlign: 'left',
                fontSize: 12,
                marginTop: 8,
                padding: '16px',
              }}
            >
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0 10px',
                  color: '#808080',
                  marginBottom: '10px',
                }}
              >
                <EmojiEventsIcon />
                <span style={{ textTransform: 'capitalize' }}>
                  {event.type}
                </span>
              </p>
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0 10px',
                  color: '#808080',
                  marginBottom: '10px',
                }}
              >
                <DateRangeIcon />
                <span>{format(new Date(event.start), 'd/MM/yyyy')}</span>
              </p>
              <p
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0 10px',
                  color: '#808080',
                  marginBottom: '10px',
                }}
              >
                <AccessTimeIcon />
                <span>
                  {format(new Date(event.start), 'hh:mm')} -{' '}
                  {format(new Date(event.end), 'hh:mm')}
                </span>
              </p>

              {event.link && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0 10px',
                    color: '#808080',
                    marginBottom: '10px',
                  }}
                >
                  <PhonelinkIcon />
                  <a
                    href={event.link}
                    target='_blank'
                    rel='noreferrer'
                    style={{
                      textDecoration: 'none',
                      color: '#10ADB9',
                    }}
                  >
                    {event.link}
                  </a>
                </div>
              )}
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default ScheduleCalendar;
