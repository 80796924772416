import { Box, Paper, Stack, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { format } from 'date-fns';
import htmlparser from 'html-react-parser';
import React from 'react';

const CapstoneTaskDetail = ({ currentCapstone }) => {
  const data = currentCapstone?.capstones?.map((individualCapstone) => {
    const capstoneTitle = individualCapstone?.capstone?.title;

    return individualCapstone?.capstone?.tasks?.map((task) => {
      const taskSubmission = individualCapstone?.capstoneSubmissions?.find(
        (taskSubmission) => {
          return taskSubmission.taskId === task._id;
        }
      );
      return {
        capstoneTitle: capstoneTitle,
        taskTitle: task.title,
        taskId: task._id,
        taskSolution: taskSubmission?.solution,
        taskSubmissionDate: taskSubmission?.lastUpdated,
        quizScore: individualCapstone?.capstoneAssessments?.percentage,
      };
    });
  });

  return (
    <div>
      {data.map((capstone, index) => {
        return (
          <Paper key={index} variant='elevation' elevation={6}>
            <Box sx={{ m: 4, p: 2 }}>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={2}
              >
                <Typography variant='h4'>
                  <Typography
                    component='span'
                    variant='h6'
                    sx={{ color: 'gray' }}
                  >
                    Capstone:{' '}
                  </Typography>
                  {capstone[0].capstoneTitle}
                </Typography>
                {capstone[0]?.quizScore && (
                  <Typography component='span' variant='h6'>
                    Quiz Score : {capstone[0]?.quizScore}
                  </Typography>
                )}
              </Stack>

              {capstone.map((capstoneTask, index) => {
                return (
                  <Box key={index} sx={{ mt: 2, mb: 4 }}>
                    <Box>
                      <Typography variant='h6' sx={{ display: 'inline' }}>
                        {capstoneTask.taskTitle}
                      </Typography>
                    </Box>
                    {capstoneTask.taskSolution ? (
                      <>
                        <Typography component='p' variant='caption'>
                          {capstoneTask.taskSubmissionDate && (
                            <>
                              Submitted:{' '}
                              {format(
                                new Date(capstoneTask.taskSubmissionDate),
                                'do LLLL yyyy'
                              )}
                            </>
                          )}
                        </Typography>
                        <Box
                          sx={{ backgroundColor: blueGrey[50], p: 2, mt: 1 }}
                        >
                          {htmlparser(capstoneTask.taskSolution)}
                        </Box>
                      </>
                    ) : (
                      'No submission yet'
                    )}
                  </Box>
                );
              })}
            </Box>
          </Paper>
        );
      })}
    </div>
  );
};

export default CapstoneTaskDetail;
