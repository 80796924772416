import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import { format } from 'date-fns';
import {
  TablePagination,
  Chip,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import AttendanceBody from './AttendanceBody';
import StickyTableListHead from '../../../layouts/batch/StickyTableListHead';
import { applySortFilter, getComparator } from '../../../helper/TableShort';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
// import CsvDownloadButton from 'react-json-to-csv';
import { ExportJsonCsv } from 'react-export-json-csv';

const AttendanceHeader = ({ attendance, allEvents }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [searched, setSearched] = useState('');
  const [showDetail, setShowDetail] = useState(false);
  const [userStatus, setUserStatus] = useState('All');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TABLE_HEAD = [
    {
      id: 'name',
      label: 'Name',
      align: 'left',
    },
    {
      id: 'eventAttent',
      label: 'Attended',
      align: 'center',
    },
    {
      id: 'attendanceRate',
      label: 'Attendance Rate',
      align: 'center',
    },
    {
      id: 'coach',
      label: 'Coach',
      align: 'center',
    },
    {
      id: 'company',
      label: 'Company',
      align: 'center',
    },
    {
      id: 'email',
      label: 'Email',
      align: 'center',
    },
  ];

  const attendanceArray = attendance.map((item) => {
    const eventAttent = item.events.filter((event) => {
      return event.attendance && event.attendance.status;
    }).length;
    const totalEventDone = allEvents.filter((event) => {
      return event.isPastEvent;
    }).length;
    const attendanceRate = totalEventDone
      ? eventAttent > totalEventDone
        ? 100
        : Math.round((eventAttent * 100) / totalEventDone)
      : 0;
    return {
      ...item,
      totalEventDone: totalEventDone,
      eventAttent: eventAttent,
      attendanceRate: attendanceRate,
      coach: item.coach.name,
      company: item.company.name,
    };
  });
  const [data, setData] = useState(attendanceArray);
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    const searchedValLower = searchedVal.toLowerCase();
    const filteredRows = attendanceArray.filter((row) => {
      return (
        row.name.toLowerCase().includes(searchedValLower) ||
        row.coach.toLowerCase().includes(searchedValLower) ||
        row.company.toLowerCase().includes(searchedValLower) ||
        row.email.toLowerCase().includes(searchedValLower)
      );
    });
    setData(filteredRows);
  };

  const activeUser = data.filter((user) => {
    if (userStatus === 'All') {
      return true;
    }
    return userStatus === user.status;
  });

  const filteredUsers = applySortFilter(
    activeUser,
    getComparator(order, orderBy)
  );
  const headers = [
    {
      key: 'Name',
      name: 'Name',
    },
    {
      key: 'Email',
      name: 'Email',
    },
    {
      key: 'Mentor',
      name: 'Mentor',
    },
    {
      key: 'Coach',
      name: 'Coach',
    },
    {
      key: 'Company',
      name: 'Company',
    },
    {
      key: 'TotalEvent',
      name: 'TotalEvent',
    },
    {
      key: 'AttendanceRate',
      name: 'AttendanceRate',
    },
  ];
  const csvData = filteredUsers.map((item) => {
    let temp = {};
    allEvents.forEach((element, index) => {
      const myevent = item.events.filter((userEvents) => {
        return userEvents._id === element._id;
      })[0];
      const text = element.attendanceUploaded
        ? myevent
          ? myevent.attendance && myevent.attendance.status
            ? 'P'
            : 'A'
          : 'NA'
        : '-';
      temp[`${element.title}-${index}`] = text;
    });
    return {
      Name: item.name,
      Email: item.email,
      Coach: item.coach,
      Company: item.company,
      Mentor: item.mentor.name,
      TotalEvent: item.totalEventDone,
      AttendanceRate: `${item.attendanceRate} %`,
      ...temp,
    };
  });

  allEvents.forEach((element, index) => {
    headers.push({
      key: `${element.title}-${index}`,
      name: `${element.title}`,
    });
  });

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Paper
          elevation={5}
          sx={{ width: 545, margin: 1, padding: 1, display: 'flex' }}
        >
          <TextField
            fullWidth
            size='small'
            label='Search Name Email Coach Company'
            value={searched}
            onChange={(e) => {
              requestSearch(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => requestSearch('')} edge='end'>
                    {searched ? <CloseIcon /> : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl sx={{ marginInline: 4 }} fullWidth size='small'>
            <InputLabel id='demo-simple-select-label'>User Status</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={userStatus}
              label='userStatus'
              onChange={(e) => setUserStatus(e.target.value)}
            >
              <MenuItem value='All'>All</MenuItem>
              <MenuItem value='Active'>Active</MenuItem>
              <MenuItem value='Inactive'>Inactive </MenuItem>
            </Select>
          </FormControl>
        </Paper>

        <ExportJsonCsv headers={headers} items={csvData}>
          <Button variant='outlined'>Attendance</Button>
        </ExportJsonCsv>
      </Stack>
      <Button onClick={() => setShowDetail(!showDetail)}>
        {showDetail ? 'Hide Details' : 'Show Details'}
      </Button>
      <TableContainer sx={{ maxHeight: 600 }} component={Paper}>
        <Table
          stickyHeader
          sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
          aria-label='table'
          size='small'
        >
          <TableHead>
            <StickyTableListHead
              order={order}
              orderBy={orderBy}
              headLabel={showDetail ? TABLE_HEAD : [TABLE_HEAD[0]]}
              onRequestSort={handleRequestSort}
            />
            {allEvents.map((event) => {
              return (
                <TableCell
                  key={event._id}
                  align='center'
                  sx={{ minWidth: 150, maxWidth: 200, fontSize: 12 }}
                >
                  {event.title}
                  {event.dateTime && (
                    <Chip
                      variant='outlined'
                      size='small'
                      label={format(new Date(event.dateTime), 'do LLL yy')}
                    />
                  )}
                  {!event.attendanceUploaded && (
                    <Chip
                      variant='outlined'
                      color='error'
                      size='small'
                      label='Attendance not added'
                    />
                  )}
                </TableCell>
              );
            })}
          </TableHead>
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <AttendanceBody
                  key={row._id}
                  row={row}
                  allEvents={allEvents}
                  showDetail={showDetail}
                />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component='div'
          count={activeUser.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
};

export default AttendanceHeader;
